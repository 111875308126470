import { composeProgress } from "apollo/utils";
import { useEnvContext } from "context/EnvContext";
import {
  SEARCH_ACTION_RESTART_SEARCH,
  SEARCH_ACTION_START_AUTOMATIC_SEARCH,
  SEARCH_ACTION_START_MANUAL_SEARCH,
} from "core/consts";
import api from "core/model/api";
import { findSearchAction } from "core/model/auctions";
import { useGetPendingSealdAccess } from "core/seald/pendingAccess";
import { Auction, QueryProgress } from "core/types";
import { useCareseekerNavigationHandlers } from "dsl/hooks";
import useSearchAction from "dsl/hooks/useSearchAction";
import { useGetPendingKeys } from "dsl/organisms/Encryption";
import { useCallback } from "react";

const useHandlePendingSessionKeys = ({
  auction,
  goToSearchOnComplete,
}: {
  auction: Auction;
  goToSearchOnComplete: boolean | undefined;
}): [
  (action: () => Promise<Auction | null> | undefined) => () => Promise<void>,
  QueryProgress,
] => {
  const { patient: patientNavigation } = useCareseekerNavigationHandlers();
  const { getPendingKeys, manualDone } = useGetPendingKeys();
  const envContext = useEnvContext();
  const { getPendingAccesses, manualDone: manualDoneSeald } =
    useGetPendingSealdAccess({
      envContext,
      createSealdAccess: api.crypto.createSealdAccess,
      getPendingSealdAccess: api.crypto.getPendingSealdAccess,
    });

  const handlePendingSessionKeys = useCallback(
    (action: () => Promise<Auction | null> | undefined) => () =>
      Promise.all([getPendingKeys(), getPendingAccesses(), action()]).then(
        () => {
          if (goToSearchOnComplete) {
            setTimeout(
              () =>
                patientNavigation.goToSearch({
                  patientId: auction.patient.id,
                  auctionId: auction.id,
                }),
              0,
            );
          }
        },
      ),
    [patientNavigation, getPendingKeys, getPendingAccesses],
  );

  return [
    handlePendingSessionKeys,
    composeProgress([manualDone, manualDoneSeald]),
  ];
};

export const useStartAutomaticSearch = ({
  auction,
  goToSearchOnComplete,
  onCompleted,
}: {
  auction: Auction;
  goToSearchOnComplete?: boolean;
  onCompleted?: () => void;
}): [() => Promise<void>, QueryProgress] => {
  const [handlePendingSessionKeys, manualDone] = useHandlePendingSessionKeys({
    auction,
    goToSearchOnComplete,
  });
  const [startAutomaticSearch, startAutomaticSearchProgress] = useSearchAction({
    actionType: SEARCH_ACTION_START_AUTOMATIC_SEARCH,
  });

  const action = useCallback(() => {
    const canStartSearchAction = findSearchAction(
      auction,
      SEARCH_ACTION_START_AUTOMATIC_SEARCH,
    );

    if (canStartSearchAction) {
      return startAutomaticSearch({ onCompleted, auction });
    }
  }, [startAutomaticSearch, auction?.search_actions]);

  return [
    handlePendingSessionKeys(action),
    composeProgress([startAutomaticSearchProgress, manualDone]),
  ];
};

export const useStartManualSearch = ({
  auction,
  goToSearchOnComplete,
  onCompleted,
}: {
  auction: Auction;
  goToSearchOnComplete?: boolean;
  onCompleted?: () => void;
}): [() => Promise<void>, QueryProgress] => {
  const [handlePendingSessionKeys, manualDone] = useHandlePendingSessionKeys({
    auction,
    goToSearchOnComplete,
  });

  const [startManualSearch, startManualSearchProgress] = useSearchAction({
    actionType: SEARCH_ACTION_START_MANUAL_SEARCH,
  });

  const action = useCallback(() => {
    const canStartSearchAction = findSearchAction(
      auction,
      SEARCH_ACTION_START_MANUAL_SEARCH,
    );
    if (canStartSearchAction) {
      return startManualSearch({ onCompleted, auction });
    }
  }, [startManualSearch, auction?.search_actions]);

  return [
    handlePendingSessionKeys(action),
    composeProgress([startManualSearchProgress, manualDone]),
  ];
};

export const useRestartSearch = ({
  auction,
  goToSearchOnComplete,
  onCompleted,
}: {
  auction: Auction;
  goToSearchOnComplete?: boolean;
  onCompleted?: () => void;
}): [(newStatus?: number) => Promise<void>, QueryProgress] => {
  const [handlePendingSessionKeys, manualDone] = useHandlePendingSessionKeys({
    auction,
    goToSearchOnComplete,
  });

  const [restartSearch, restartProgress] = useSearchAction({
    actionType: SEARCH_ACTION_RESTART_SEARCH,
  });

  const action = useCallback(
    (newStatus?: number) => {
      const canRestartSearchAction = findSearchAction(
        auction,
        SEARCH_ACTION_RESTART_SEARCH,
      );
      if (canRestartSearchAction) {
        return restartSearch({
          auction,
          onCompleted,
          context: { status: newStatus },
        });
      }
    },
    [restartProgress, auction?.search_actions],
  );

  return [
    handlePendingSessionKeys(action),
    composeProgress([restartProgress, manualDone]),
  ];
};
