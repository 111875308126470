import {
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
  SEARCH_ACTION_GENERATE_AHB_FORMS,
} from "core/consts";
import { getSearchActions } from "core/model/auctions";
import { Auction, QueryProgress, RehabFormName } from "core/types";
import { format, fromUnixTime } from "date-fns";
import { useWarnBeforeExit } from "dsl/hooks/useWarnBeforeExit";
import { useWarnBeforeNavigate } from "dsl/hooks/useWarnBeforeNavigate";
import { ReactNode, useState } from "react";
import { RehabFormFormGetters } from "./RehabForms/configs";
import { TransitionalCareFormGetters } from "./TransitionalCare/Form/Import/utils";

export const useImportFormData = ({
  auction,
  formGetters,
  formOnChange,
}: {
  auction: Auction;
  formGetters:
    | TransitionalCareFormGetters
    | RehabFormFormGetters<RehabFormName>;
  formOnChange: (value: any, statePath: string, validation: any) => void;
}) => {
  const [queryProgress, setQueryProgress] = useState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );
  const importFormData = () => {
    setQueryProgress(QUERY_PROGRESS_PENDING);
    Object.entries(formGetters).forEach(([field, fieldGetter]) => {
      if (!fieldGetter) return;
      const value = fieldGetter(auction);

      formOnChange(value ?? null, field, null);
    });
    setQueryProgress(QUERY_PROGRESS_SUCCEED);
    return;
  };
  return { importProgress: queryProgress, importFormData };
};

export const ExitPreventionWrapper = ({
  children,
  dirty,
}: {
  children: ReactNode;
  dirty: boolean;
}) => {
  useWarnBeforeExit({ skip: !dirty });
  useWarnBeforeNavigate({
    skip: !dirty,
  });

  return <>{children}</>;
};

export function assertUnreachable(x: never): never {
  throw new Error(`Unhandled case: ${x}`);
}

export const formatDate = (timestamp: number, locale: Locale) =>
  format(fromUnixTime(timestamp), "P", { locale });

export const shouldShowOldRehabForms = (auction: Auction): boolean => {
  const { [SEARCH_ACTION_GENERATE_AHB_FORMS]: oldRehabFormAction } =
    getSearchActions(auction);

  return !!(oldRehabFormAction && !oldRehabFormAction.context?.disabled);
};
