/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  CARESEEKER_TYPE_PROVIDER_SEARCH,
  CORONA_NOT_TESTED,
  CORONA_TESTED,
  LIVING_SITUATION_OTHER,
  PREDICAMENT_STATE_NOT_APPLICABLE,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_TRANSPORT,
} from "core/consts";
import { useDateLocale } from "core/hooks/useDateLocale";
import { getName } from "core/model/accounts";
import { getAge, getCareLevelValue, getInterval } from "core/model/patients";
import { getEncryptedValue } from "core/model/patients/encryption";
import { formatUnixDate } from "core/model/utils/dates";
import {
  activateNewBarthelAssessment,
  activateSingerAssessment,
} from "core/model/utils/featureFlags";
import { formatLocation } from "core/model/utils/location";
import {
  ONTOLOGY_COMMUNICATION,
  ONTOLOGY_MOBILITY,
  ONTOLOGY_ORIENTATION,
  ONTOLOGY_SOLUTION,
  ONTOLOGY_SPECIALIZATIONS,
} from "core/model/utils/ontologies";
import { concatStrings } from "core/model/utils/strings";
import { renderMultiLine } from "core/model/utils/text";
import {
  Auction,
  AuctionRequest as AuctionRequestType,
  Careseeker,
  CoronaTest,
  GetOntologyType,
  MedicalProductType,
  Patient,
  SearchType,
  SocialWorker,
} from "core/types";
import { margin } from "ds_legacy/materials/metrics";
import { Body, Email, Subheading, Title } from "ds_legacy/materials/typography";
import PhoneNumber from "dsl/atoms/PhoneNumber";
import { usePrintWindow } from "dsl/atoms/PrintButton";
import { convertToNewAssessment } from "dsl/ecosystems/IndependenceScaleSummaryPage/shared";
import { getSelectedOntologies } from "dsl/ecosystems/PatientProfile";
import { careneedsFilters } from "dsl/organisms/Infos/PatientInformation/CareNeedsCard";
import { generalInformationFilters } from "dsl/organisms/Infos/PatientInformation/InformationCard";
import {
  IcdCodesCommon,
  IndependenceScaleResult,
  coronaSwitch,
  medicalFilters,
} from "dsl/organisms/Infos/PatientInformation/MedicalCard";
import {
  ChipInfo,
  ProviderSearchHeader,
} from "dsl/organisms/Infos/PatientInformation/OverviewCard";
import { solutionSortOrder } from "dsl/organisms/PatientCreatePage/PatientCreateForm";
import { useExtracted } from "dsl/organisms/PatientMenu";
import React from "react";
import { getPredicamentValue } from "react-forms-state";
import { useLocale, useTranslations } from "translations";
import Translations from "translations/types";
import GrantStatusField from "../GrantStatusField";
import ShortTermCareGrant from "../ShortTermCareGrant";
import {
  formatProductString,
  locationValue,
  medicalSuppliesFilters,
} from "./MedicalSuppliesSearchCard";
import TransportInfoCard from "./TransportInfoCard";
import {
  formatPickupStringValue,
  transportFilters,
} from "./TransportSearchCard";
import {
  BodyWrapper,
  BooleanField,
  OntologyField,
  StringField,
  activableInputCollectionIsNotEmpty,
  isInfectionsAndGermsPredicamentNegative,
} from "./shared";

const BodyWithBreak = ({
  children,
  faxPrint,
}: {
  children: any;
  faxPrint: boolean | null | undefined;
}) => (
  <>
    <Body>{children}</Body>
    {faxPrint ? <br /> : null}
  </>
);

function EmptyablePrintCategory({
  children,
  empty,
  faxPrint,
  title,
  translations,
}: {
  children: React.ReactNode;
  empty: boolean;
  faxPrint: boolean | null | undefined;
  title: string;
  translations: Translations;
}) {
  return (
    <div>
      <Subheading bold>{title}</Subheading>
      {empty ? (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.auctionRequest.noAdditionalInformationProvided}
        </BodyWithBreak>
      ) : (
        children
      )}
    </div>
  );
}

function EmptiablePrintField({
  faxPrint,
  prefix,
  value,
}: {
  faxPrint: boolean | null | undefined;
  prefix: string;
  value: string | null | undefined;
}) {
  if (value != null)
    return (
      <BodyWithBreak faxPrint={faxPrint}>
        {concatStrings(prefix, value, ": ")}
      </BodyWithBreak>
    );

  return null;
}

function Diagnosis({
  auction,
  faxPrint,
  forClinic,
  getOntology,
  patient,
  request,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  forClinic: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  request?: AuctionRequestType;
  translations: Translations;
}) {
  if (request?.is_provider_search_request) return null;

  const categories: Array<{ Component: React.ElementType; key: string }> =
    (patient?.diagnosis &&
      medicalDiagnosisComponents.filter((c) =>
        medicalFilters[c.key].exists(patient.diagnosis),
      )) ||
    [];

  if (!categories || categories.length == 0) return null;
  return (
    <React.Fragment>
      <Title bold>{translations.patient.medicalDiagnosis.stepperTitle}</Title>
      <MainDiagnosis
        faxPrint={faxPrint}
        patient={patient}
        translations={translations}
      />
      <SecondaryDiagnosis
        faxPrint={faxPrint}
        patient={patient}
        translations={translations}
      />
      <MorbidityStatus
        faxPrint={faxPrint}
        status={patient.diagnosis?.multimorbidity}
        translations={translations}
      />
      <AdditionalSpecializations
        faxPrint={faxPrint}
        auction={auction}
        translations={translations}
        getOntology={getOntology}
      />
      <MedicalHistory
        faxPrint={faxPrint}
        patient={patient}
        translations={translations}
      />
      <AdditionalExaminationsNecessary
        faxPrint={faxPrint}
        patient={patient}
        translations={translations}
      />
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          patient,
          forClinic,
          getOntology,
          faxPrint,
        }),
      )}
    </React.Fragment>
  );
}

function MentalHealth({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  const categories =
    patient?.diagnosis &&
    mentalHealthComponents.filter((c) =>
      medicalFilters[c.key].exists(patient.diagnosis),
    );

  if (!categories || categories.length == 0) return null;
  return (
    <React.Fragment>
      <Title bold>{translations.patient.mentalHealth}</Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          patient,
          getOntology,
          faxPrint,
        }),
      )}
    </React.Fragment>
  );
}

function PhysicalHealth({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  const categories =
    patient?.diagnosis &&
    physicalComponents.filter((c) =>
      medicalFilters[c.key].exists(patient.diagnosis),
    );

  if (!categories || categories.length == 0) return null;
  return (
    <React.Fragment>
      <Title>{translations.patient.physicalHealth}</Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          patient,
          getOntology,
          faxPrint,
        }),
      )}
    </React.Fragment>
  );
}

function TransportSearch({
  auction,
  faxPrint,
  getOntology,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  translations: Translations;
}) {
  if (auction.search_type !== SEARCH_TYPE_TRANSPORT) return null;
  const categories = transportSearchComponentMapping.filter((c) =>
    transportFilters[c.key].exists(auction),
  );
  if (!categories || categories.length == 0) return null;
  return (
    <React.Fragment>
      <Title bold>
        {translations.assessments.transportSearch.titleTransportSearch}
      </Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          auction,
          getOntology,
          faxPrint,
        }),
      )}
    </React.Fragment>
  );
}

function GeneralInformationContainer({
  auction,
  faxPrint,
  forClinic,
  getOntology,
  patient,
  request,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  forClinic: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  request: AuctionRequestType | null | undefined;
  translations: Translations;
}) {
  const categories = generalInformationComponents.filter((c: ToType) =>
    generalInformationFilters[c.key].exists(auction),
  );
  if (!categories || categories.length == 0) return null;
  return (
    <React.Fragment>
      <Title bold>{translations.patient.patientInformation.stepperTitle}</Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          patient,
          auction,
          request,
          forClinic,
          getOntology,
          faxPrint,
        }),
      )}
    </React.Fragment>
  );
}

function MedicalSuppliesContainer({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | undefined;
  translations: Translations;
}) {
  if (
    !(
      [SEARCH_TYPE_HOME_CARE, SEARCH_TYPE_MEDICAL_SUPPLIES] as SearchType[]
    ).includes(auction.search_type)
  )
    return null;
  const categories = medicalSuppliesSearchComponents.filter((c: ToType) =>
    medicalSuppliesFilters[c.key].exists(auction),
  );

  if (!categories || categories.length == 0) return null;
  return (
    <React.Fragment>
      <Title bold>
        {translations.assessments.medicalSupplies.medicalSupplies}
      </Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          auction,
          faxPrint,
        }),
      )}
    </React.Fragment>
  );
}

function CareNeeds({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { care_needs } = patient;
  const categories =
    patient.care_needs &&
    careneedsComponents.filter((c) =>
      careneedsFilters[c.key].exists(care_needs),
    );

  if (!categories || categories.length == 0) return null;
  return (
    <React.Fragment>
      <Title bold>{translations.patient.careNeeds.stepperTitle}</Title>
      {categories.map((c) =>
        React.createElement(c.Component, {
          key: c.key,
          translations,
          patient,
          faxPrint,
        }),
      )}
    </React.Fragment>
  );
}

function TransportInfo({
  auction,
  getOntology,
}: {
  auction: Auction;
  getOntology: GetOntologyType;
}) {
  return (
    <TransportInfoCard auction={auction} getOntology={getOntology} forPrint />
  );
}

function PrintPatientInfo({
  auction,
  auctionRequest,
  faxPrint,
  forClinic,
  getOntology,
}: {
  auction: Auction;
  auctionRequest?: AuctionRequestType;
  faxPrint?: boolean;
  forClinic?: boolean;
  getOntology: GetOntologyType;
}) {
  usePrintWindow();
  const translations = useTranslations();
  const isProviderSearchRequest = !!auctionRequest?.is_provider_search_request;
  return (
    <div style={{ margin: margin(0, 4) }}>
      <GeneralInformationContainer
        faxPrint={faxPrint}
        getOntology={getOntology}
        patient={auction.patient}
        auction={auction}
        request={auctionRequest}
        forClinic={forClinic}
        translations={translations}
      />
      {!isProviderSearchRequest && (
        <>
          <Diagnosis
            faxPrint={faxPrint}
            getOntology={getOntology}
            patient={auction.patient}
            auction={auction}
            request={auctionRequest}
            forClinic={forClinic}
            translations={translations}
          />

          <MentalHealth
            faxPrint={faxPrint}
            getOntology={getOntology}
            patient={auction.patient}
            translations={translations}
          />
          <PhysicalHealth
            faxPrint={faxPrint}
            getOntology={getOntology}
            patient={auction.patient}
            translations={translations}
          />
          <MedicalSuppliesContainer
            auction={auction}
            translations={translations}
            faxPrint={faxPrint}
          />
          <TransportSearch
            faxPrint={faxPrint}
            getOntology={getOntology}
            auction={auction}
            translations={translations}
          />
        </>
      )}

      <CareNeeds
        faxPrint={faxPrint}
        patient={auction.patient}
        translations={translations}
      />
      <TransportInfo auction={auction} getOntology={getOntology} />
    </div>
  );
}

export default React.memo(PrintPatientInfo);

function BasicCategory({
  column,
  faxPrint,
  multiline = false,
  text,
  title,
}: {
  column?: boolean;
  faxPrint: boolean | null | undefined;
  multiline?: boolean;
  text: string;
  title: string;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: column ? "column" : undefined,
        alignItems: "baseline",
      }}
    >
      <Subheading bold>{title}</Subheading>
      <BodyWithBreak faxPrint={faxPrint}>
        {multiline ? renderMultiLine(text) : text}
      </BodyWithBreak>
    </div>
  );
}

function HospitalContactInformation({
  careseeker,
  getOntology,
  socialWorker,
  translations,
}: {
  careseeker: Careseeker | undefined;
  getOntology: GetOntologyType;
  socialWorker: SocialWorker | undefined;
  translations: Translations;
}) {
  if (
    !socialWorker ||
    !careseeker ||
    careseeker.type === CARESEEKER_TYPE_PROVIDER_SEARCH
  ) {
    return null;
  }
  return (
    <>
      <Subheading bold>
        {translations.careproviderProfile.clinicContactInformation({
          careseekerName: careseeker.name || "",
        })}
      </Subheading>
      <StringField
        value={getName(socialWorker, getOntology)}
        testId="careseeker_name"
      />
      <PhoneNumber
        phone={socialWorker.phone}
        prefix={translations.people.tel}
      />
      <PhoneNumber
        phone={socialWorker.mobile_phone}
        prefix={translations.people.mobile}
      />

      {socialWorker.email && (
        <div>
          <Email href={`mailto:${socialWorker.email}`}>
            {translations.people.mailShort}&nbsp;{socialWorker.email}
          </Email>
        </div>
      )}
    </>
  );
}

export function RelevantProviderInformation({
  auction,
  faxPrint,
  forClinic,
  getOntology,
  patient,
  request,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  forClinic: boolean;
  getOntology: GetOntologyType;
  patient: Patient;
  request: AuctionRequestType;
  translations: Translations;
}) {
  const {
    careseeker,
    diagnosis,
    external_id,
    profile,
    social_worker,
    user_id,
  } = patient;
  const { hospital_stay } = diagnosis ?? {};
  const { first_name, last_name } = profile ?? {};
  const isCare = auction?.search_type === SEARCH_TYPE_CARE;
  const extracted = useExtracted(auction);
  const typeOfTransfer = getSelectedOntologies(
    extracted.solutions,
    ONTOLOGY_SOLUTION,
    getOntology,
    solutionSortOrder,
  );

  const specialization = getSelectedOntologies(
    extracted.specializations,
    ONTOLOGY_SPECIALIZATIONS,
    getOntology,
  );
  return (
    <div>
      {user_id != null && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.patientId}: ${user_id}`}</BodyWithBreak>
      )}
      {!!request?.is_provider_search_request && (
        <ProviderSearchHeader translations={translations} print />
      )}
      {forClinic && (
        <>
          {careseeker && (
            <BodyWithBreak
              faxPrint={faxPrint}
            >{`${translations.auctionRequest.hospitalName}: ${careseeker.name}`}</BodyWithBreak>
          )}
          {!!typeOfTransfer && (
            <BodyWithBreak
              faxPrint={faxPrint}
            >{`${translations.auctionRequest.typeOfTransfer}: ${typeOfTransfer}`}</BodyWithBreak>
          )}
          {!!specialization && (
            <BodyWithBreak
              faxPrint={faxPrint}
            >{`${translations.ontologies.specializations.key}: ${specialization}`}</BodyWithBreak>
          )}
          <br />
        </>
      )}
      {forClinic && external_id != null && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.externalId}: ${external_id}`}
        </BodyWithBreak>
      )}
      {first_name?.decrypted && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.firstName}: ${first_name.decrypted}`}</BodyWithBreak>
      )}
      {last_name?.decrypted && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.lastName}: ${last_name.decrypted}`}</BodyWithBreak>
      )}
      {request?.solutions?.length && (
        <div>
          <ChipInfo
            getOntology={getOntology}
            requestSolutions={request?.solutions}
            auction={auction}
            oldAuction={null}
            translations={translations}
          />
        </div>
      )}
      {!forClinic && !faxPrint && (
        <HospitalContactInformation
          getOntology={getOntology}
          socialWorker={social_worker}
          careseeker={careseeker}
          translations={translations}
        />
      )}
      {!forClinic &&
        hospital_stay &&
        (hospital_stay.doctor_in_charge_in_hospital ||
          hospital_stay.doctor_in_charge_in_hospital_phone) && (
          <>
            {hospital_stay.doctor_in_charge_in_hospital && (
              <BodyWithBreak
                faxPrint={faxPrint}
              >{`${translations.patient.doctorInCharge}: ${hospital_stay.doctor_in_charge_in_hospital}`}</BodyWithBreak>
            )}
            {!isCare && hospital_stay.doctor_in_charge_in_hospital_phone && (
              <BodyWithBreak
                faxPrint={faxPrint}
              >{`${translations.people.tel}: ${hospital_stay.doctor_in_charge_in_hospital_phone}`}</BodyWithBreak>
            )}
          </>
        )}
    </div>
  );
}

function GeneralInformation({
  auction,
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  const { profile } = patient;
  const search_type = auction?.search_type;
  if (!profile) return null;
  const location = auction?.profile?.search_location;
  const formattedAddressInfo = location
    ? formatLocation({ location, translations })
    : null;
  const isProviderSearchPatient =
    patient?.careseeker?.type === CARESEEKER_TYPE_PROVIDER_SEARCH;

  const weight =
    getEncryptedValue(profile?.weight) ||
    getInterval(
      patient,
      auction.search_type,
      isProviderSearchPatient,
      "weight_interval",
      translations,
    );
  const height =
    getEncryptedValue(profile?.height) ||
    getInterval(
      patient,
      auction.search_type,
      isProviderSearchPatient,
      "height_interval",
      translations,
    );

  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.generalInformation}
      </Subheading>
      {profile.gender && (
        <BodyWithBreak faxPrint={faxPrint}>{`${
          translations.patient.gender
        }: ${getOntology({
          type: "gender",
          key: profile.gender,
        })}`}</BodyWithBreak>
      )}
      {profile.birth_date?.decrypted ? (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.dateOfBirth}: ${profile.birth_date.decrypted}`}</BodyWithBreak>
      ) : (
        profile.age_interval && (
          <BodyWithBreak faxPrint={faxPrint}>{`${translations.patient.age}: ${
            getAge(profile.age_interval, search_type) || ""
          }`}</BodyWithBreak>
        )
      )}
      {weight && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.formGeneration.weight}: ${weight}kg`}</BodyWithBreak>
      )}
      {height && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.formGeneration.height}: ${height}cm`}</BodyWithBreak>
      )}
      {formattedAddressInfo && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.address.address}: ${formattedAddressInfo}`}</BodyWithBreak>
      )}
    </div>
  );
}

function Insurance({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.profile?.financing) return null;
  const { insurance, insurance_number, patient_has_health_insurance_state } =
    patient.profile.financing;
  if (!insurance && !insurance_number) return null;
  const insuranceNumber = getEncryptedValue(insurance_number);
  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.insurance}
      </Subheading>
      {patient_has_health_insurance_state !==
        PREDICAMENT_STATE_NOT_APPLICABLE && (
        <BodyWithBreak faxPrint={faxPrint}>
          {getPredicamentValue({
            label: translations.patient.hasHealthInsurance,
            translations,
            value: patient_has_health_insurance_state,
          })}
        </BodyWithBreak>
      )}
      {insurance?.name && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.insuranceProvider}: ${insurance.name}`}</BodyWithBreak>
      )}
      {insuranceNumber && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.insuranceNumber}: ${insuranceNumber}`}</BodyWithBreak>
      )}
    </div>
  );
}

function Carelevel({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.profile?.financing) return null;
  const { carelevel, public_care_insurance_status } = patient.profile.financing;
  if (!carelevel && !public_care_insurance_status) return null;
  return (
    <div>
      <Subheading bold>{translations.patient.carelevel}</Subheading>
      <BodyWithBreak faxPrint={faxPrint}>
        {getCareLevelValue(carelevel, translations).label}
      </BodyWithBreak>

      {(carelevel?.higher_level || public_care_insurance_status) && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.hasAppliedforHigherCarelevel}
        </BodyWithBreak>
      )}
      {carelevel?.expedited_request && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.expeditedRequest}
        </BodyWithBreak>
      )}
      {carelevel?.has_applied && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.hasAppliedforCarelevel}
        </BodyWithBreak>
      )}
    </div>
  );
}

function Payout({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.profile?.financing) return null;
  const { payout } = patient.profile.financing;
  return payout && payout !== 0 ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.patientInformation.payout}
      text={getOntology({ type: "grantsType", key: payout })}
      column
    />
  ) : null;
}

function LivingSituation({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient?.profile) return null;
  const {
    lift_available_description,
    lift_available_state,
    living_situation,
    living_situation_other,
    unsafe_current_domestic_situation,
  } = patient.profile;

  if (
    !living_situation &&
    !lift_available_state &&
    !lift_available_description &&
    !unsafe_current_domestic_situation
  )
    return null;
  const livingSituation = getOntology({
    type: "livingSituation",
    key: living_situation as number,
  });
  const other = living_situation === LIVING_SITUATION_OTHER;
  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.livingSituation}
      </Subheading>
      <BodyWithBreak faxPrint={faxPrint}>
        {`${translations.patient.patientInformation.patientIsLiving}: ${
          other ? living_situation_other || "" : livingSituation
        }`}
      </BodyWithBreak>
      {lift_available_state && (
        <BodyWithBreak faxPrint={faxPrint}>
          {getPredicamentValue({
            label: translations.patient.patientInformation.liftAvailable,
            value: lift_available_state,
            translations,
          })}
        </BodyWithBreak>
      )}
      {lift_available_description && (
        <BodyWithBreak faxPrint={faxPrint}>
          {lift_available_description}
        </BodyWithBreak>
      )}
      {unsafe_current_domestic_situation && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.patientInformation.unsafeCurrentDomesticSituation}: ${unsafe_current_domestic_situation}`}
        </BodyWithBreak>
      )}
    </div>
  );
}

function GeneralPractitioner({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.profile) return null;
  const { general_practitioner } = patient.profile;
  if (!general_practitioner) return null;
  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.generalPractitionerTitle}
      </Subheading>
      <BodyWithBreak faxPrint={faxPrint}>
        {general_practitioner.name}
      </BodyWithBreak>
      <BodyWithBreak faxPrint={faxPrint}>
        {general_practitioner.contact_details}
      </BodyWithBreak>
    </div>
  );
}

function Communication({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.profile) return null;
  const { communication } = patient.profile;

  if (!communication) return null;
  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.decisionsLanguages}
      </Subheading>

      {communication.patient_is_contact && (
        <BodyWithBreak faxPrint={faxPrint}>
          {communication.patient_is_contact_description?.decrypted
            ? `${translations.patient.patientInformation.contactIsPatient}: ${communication.patient_is_contact_description.decrypted}`
            : translations.patient.patientInformation.contactIsPatient}
        </BodyWithBreak>
      )}
      {communication.relatives_available && (
        <BodyWithBreak faxPrint={faxPrint}>
          {communication.relatives_description?.decrypted
            ? `${translations.patient.patientInformation.relativesAvailable}: ${communication.relatives_description?.decrypted}`
            : translations.patient.patientInformation.relativesAvailable}
        </BodyWithBreak>
      )}
      {communication.has_guardian && (
        <>
          <BodyWithBreak faxPrint={faxPrint}>
            {`${translations.patient.patientInformation.hasGuardian}
            ${
              communication.guardian_contact_information?.decrypted
                ? `${translations.general.colon} ${communication.guardian_contact_information?.decrypted}`
                : communication.guardian_description
                ? `${translations.general.colon} ${communication.guardian_description}`
                : ""
            }`}
          </BodyWithBreak>
          {communication.guardian_requested && (
            <BodyWithBreak faxPrint={faxPrint}>
              {translations.patient.patientInformation.guardianRequested}
            </BodyWithBreak>
          )}
        </>
      )}
      {communication.patient_cannot_communicate_in_german && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${
            translations.patient.patientInformation
              .patientCannotCommunicateInGerman.label
          }: ${communication.patient_language || ""}`}
        </BodyWithBreak>
      )}
      {communication.patient_uses_sign_language && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.patientInformation.patientUsesSignInLanguage.label}: ${communication.patient_uses_sign_language}`}
        </BodyWithBreak>
      )}
    </div>
  );
}

function Payers({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  const { payers: payersData } = auction;

  if (!payersData) {
    return null;
  }
  return (
    <div>
      <Subheading margin={margin(1, 0)} bold>
        {translations.patient.patientInformation.payers}
      </Subheading>
      {payersData.insurance?.name && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.patientInformation.payersInsurance}: ${payersData.insurance.name}`}
        </BodyWithBreak>
      )}
      {payersData.civil_servants_aid && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.patientInformation.civilServantsAid}: ${payersData.civil_servants_aid}`}
        </BodyWithBreak>
      )}
      {payersData.public_accident_insurance && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.patientInformation.publicAccidentInsurance}: ${payersData.public_accident_insurance}`}
        </BodyWithBreak>
      )}
      {payersData.public_pension?.name && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.patientInformation.publicPension}: ${payersData.public_pension.name}`}
        </BodyWithBreak>
      )}
      {payersData.self_payer && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.patientInformation.selfPayer}: ${payersData.self_payer}`}
        </BodyWithBreak>
      )}
      {payersData.other_payer && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.patientInformation.payersOther}: ${payersData.other_payer}`}
        </BodyWithBreak>
      )}
      {payersData.further_information && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.patientInformation.payersMoreInformations}: ${payersData.further_information}`}
        </BodyWithBreak>
      )}
      {payersData.supplementary_insurance_state && (
        <BodyWithBreak faxPrint={faxPrint}>
          {getPredicamentValue({
            label:
              translations.patient.patientInformation.supplementaryInsurance,
            value: payersData.supplementary_insurance_state,
            translations,
          })}
        </BodyWithBreak>
      )}
      {payersData.supplementary_insurance && (
        <BodyWithBreak faxPrint={faxPrint}>
          {payersData.supplementary_insurance}
        </BodyWithBreak>
      )}
      {payersData.supplementary_payment_state && (
        <BodyWithBreak faxPrint={faxPrint}>
          {getPredicamentValue({
            label: translations.patient.patientInformation.supplementaryPayment,
            value: payersData.supplementary_payment_state,
            translations,
          })}
        </BodyWithBreak>
      )}
      {payersData.supplementary_payment && (
        <BodyWithBreak faxPrint={faxPrint}>
          {payersData.supplementary_payment}
        </BodyWithBreak>
      )}
      {payersData.free_from_supplementary_payment_state !== 0 && (
        <BodyWithBreak faxPrint={faxPrint}>
          {getPredicamentValue({
            label:
              translations.patient.patientInformation
                .freeFromSupplementaryPayment,
            translations,
            value: payersData.free_from_supplementary_payment_state,
          })}
        </BodyWithBreak>
      )}
    </div>
  );
}

function Grants({
  auction,
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  auction: Auction | undefined;
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.profile) return null;
  const { financing } = patient.profile;
  if (auction?.search_type === SEARCH_TYPE_HOSPITAL || !financing) return null;

  const keys = [
    "reimbursment_for_help_at_home",
    "reimbursment_secured",
    "preventative_care",
    "short_term_care",
    "relief_services",
    "private_payment_bool",
    "more_information",
    "social_help_recipient_bool",
  ];
  if (!keys.find((attr) => (financing as ToType)[attr] != null)) return null;
  return (
    <div>
      <Subheading bold>{translations.patient.grants}</Subheading>

      <GrantStatusField
        value={financing.reimbursment_for_help_at_home}
        testId="reimbursment_for_help_at_home"
        label={translations.patient.reimbursmentForHelpAtHome}
        getOntology={getOntology}
      />
      <GrantStatusField
        value={financing.reimbursment_secured}
        testId="reimbursment_secured"
        getOntology={getOntology}
        label={translations.patient.reimbursmentSecured}
      />
      <GrantStatusField
        getOntology={getOntology}
        value={financing.preventative_care}
        testId="preventative_care"
        label={translations.patient.preventativeCare}
      />
      <ShortTermCareGrant
        getOntology={getOntology}
        value={financing.short_term_care}
        testId="short_term_care"
        withDiff={false}
      />
      <GrantStatusField
        value={financing.social_help_recipient_bool}
        testId="social_help_recipient_bool"
        getOntology={getOntology}
        label={translations.patient.socialHelpRecipient}
      />
      <BooleanField
        value={financing.private_payment_bool}
        label={translations.patient.patientInformation.acceptsPrivatePayment}
      />
      <OntologyField
        getOntology={getOntology}
        value={financing.relief_services}
        type="reliefServices"
        wrap={(content) => (
          <BodyWrapper>
            <Body
              margin={margin(0, -1.5, 0, 0)}
            >{`${translations.ontologies.reliefServices.key}${translations.general.colon} `}</Body>
            {content}
          </BodyWrapper>
        )}
      />
      {financing.more_information && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.patientInformation.grantsMoreInformation}: ${financing.more_information}`}</BodyWithBreak>
      )}
    </div>
  );
}

function Preferences({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.profile) return null;
  const { preferences } = patient.profile;
  if (!preferences) return null;
  return (
    <div>
      <Subheading bold>
        {translations.patient.patientInformation.specialRequirements}
      </Subheading>
      {preferences.room_type != null && (
        <BodyWithBreak faxPrint={faxPrint}>
          {getOntology({ type: "roomType", key: preferences.room_type })}
        </BodyWithBreak>
      )}
      {preferences.barrier_free_room && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.barrierFreeRoom}
        </BodyWithBreak>
      )}
      {preferences.accompanying_person && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.accompanyingPerson}
        </BodyWithBreak>
      )}
      {preferences.specialist_doctor && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.specialistDoctor}
        </BodyWithBreak>
      )}
    </div>
  );
}

function MainDiagnosis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { main_diagnosis } = patient?.diagnosis || {};
  return main_diagnosis ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalDiagnosis.mainDiagnosis.label}
      text={main_diagnosis}
      multiline
      column
    />
  ) : null;
}

function SecondaryDiagnosis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { secondary_diagnosis } = patient.diagnosis;
  return secondary_diagnosis ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalDiagnosis.secondaryDiagnosis}
      text={secondary_diagnosis}
      multiline
      column
    />
  ) : null;
}

function AdditionalSpecializations({
  auction,
  faxPrint,
  getOntology,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  translations: Translations;
}) {
  return (
    <EmptyablePrintCategory
      translations={translations}
      title={translations.patient.specialisedAdvices}
      empty={auction.additional_specializations === null}
      faxPrint={faxPrint}
    >
      <OntologyField
        getOntology={getOntology}
        value={auction.additional_specializations}
        type="specializations"
      />
    </EmptyablePrintCategory>
  );
}

function MorbidityStatus({
  faxPrint,
  status,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  status: boolean | null | undefined;
  translations: Translations;
}) {
  return status ? (
    <BodyWithBreak faxPrint={faxPrint}>
      {translations.patient.medicalDiagnosis.multiMorbidPatient}
    </BodyWithBreak>
  ) : null;
}

function MedicalHistory({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { medical_history } = patient.diagnosis;
  return medical_history ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalHistory}
      text={medical_history}
      multiline
      column
    />
  ) : null;
}

function AdditionalExaminationsNecessary({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { additional_examinations_necessary } = patient.diagnosis;
  return additional_examinations_necessary ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.additionalExaminations}
      text={additional_examinations_necessary}
      multiline
      column
    />
  ) : null;
}

function PalliativeCare({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { palliative } = patient.care_needs;
  if (!palliative) return null;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.palliativeCare.label}
      empty={
        !palliative.description && !palliative.palliative_certificate_needed
      }
      translations={translations}
    >
      <BodyWithBreak faxPrint={faxPrint}>
        {palliative.description}
      </BodyWithBreak>
      <BodyWithBreak faxPrint={faxPrint}>
        {translations.patient.medicalDiagnosis.palliativeCertificateNeeded}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function DependencyDiagnosis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.dependency_diagnosis) {
    return null;
  }

  const { dependency_diagnosis } = patient.diagnosis;

  return (
    <div>
      <Subheading bold>{translations.patient.autonomy}</Subheading>
      {activateNewBarthelAssessment() ? (
        <>
          <IndependenceScaleResult
            testId="barthel-assessment-result"
            title={
              translations.patient.medicalDiagnosis.barthelIndexPlaceholder
            }
            value={
              dependency_diagnosis?.barthel_index_list?.length
                ? convertToNewAssessment(
                    dependency_diagnosis.barthel_index_list,
                  )
                : dependency_diagnosis?.barthel_assessment
            }
          />
        </>
      ) : (
        dependency_diagnosis.barthel_index && (
          <BodyWithBreak faxPrint={faxPrint}>
            {`${translations.patient.medicalDiagnosis.barthelIndexPlaceholder}: ${dependency_diagnosis.barthel_index}`}
          </BodyWithBreak>
        )
      )}
      {activateSingerAssessment() && (
        <IndependenceScaleResult
          testId="singer-assessment-result"
          title={translations.patient.medicalDiagnosis.singerIndexPlaceholder}
          value={dependency_diagnosis?.singer_assessment}
        />
      )}
      {activateNewBarthelAssessment() ? (
        <IndependenceScaleResult
          testId="early-rehab-assessment-result"
          title={
            translations.patient.medicalDiagnosis
              .earlyRehabBarthelIndexPlaceholder
          }
          value={
            dependency_diagnosis?.early_rehab_barthel_index_list?.length
              ? convertToNewAssessment(
                  dependency_diagnosis.early_rehab_barthel_index_list,
                )
              : dependency_diagnosis?.early_rehab_assessment
          }
        />
      ) : (
        dependency_diagnosis.early_rehab_barthel_index && (
          <BodyWithBreak faxPrint={faxPrint}>
            {`${translations.patient.medicalDiagnosis.earlyRehabBarthelIndexPlaceholder}: ${dependency_diagnosis.early_rehab_barthel_index}`}
          </BodyWithBreak>
        )
      )}
      {dependency_diagnosis.requires_walking_aid && (
        <BodyWithBreak faxPrint={faxPrint}>
          {getPredicamentValue({
            label: translations.patient.needsHelpWalking,
            translations,
            value: dependency_diagnosis.requires_walking_aid,
          })}
        </BodyWithBreak>
      )}
      {dependency_diagnosis.requires_support_with_feeding && (
        <BodyWithBreak faxPrint={faxPrint}>
          {getPredicamentValue({
            label: translations.patient.needsHelpEating,
            translations,
            value: dependency_diagnosis.requires_support_with_feeding,
          })}
        </BodyWithBreak>
      )}
      {dependency_diagnosis.agitated && (
        <>
          <BodyWithBreak faxPrint={faxPrint}>
            {getPredicamentValue({
              label: translations.patient.agitationOrientation,
              translations,
              value: dependency_diagnosis.agitated,
            })}
          </BodyWithBreak>
          {dependency_diagnosis.agitated_description && (
            <BodyWithBreak faxPrint={faxPrint}>
              {dependency_diagnosis.agitated_description}
            </BodyWithBreak>
          )}
        </>
      )}
      {dependency_diagnosis?.has_help_at_home && (
        <BodyWithBreak faxPrint={faxPrint}>
          {getPredicamentValue({
            label: translations.patient.hasHelpAtHome,
            translations,
            value: dependency_diagnosis.has_help_at_home,
          })}
        </BodyWithBreak>
      )}
      {dependency_diagnosis?.returning_home_short_term && (
        <BodyWithBreak faxPrint={faxPrint}>
          {getPredicamentValue({
            label: translations.patient.medicalDiagnosis.shortTerm,
            translations,
            value: dependency_diagnosis.returning_home_short_term,
          })}
        </BodyWithBreak>
      )}
    </div>
  );
}

function ChronicPains({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { chronic_pains } = patient.diagnosis;
  return chronic_pains ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalDiagnosis.chronicPains}
      text={chronic_pains}
    />
  ) : null;
}

function ImportantInformation({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient?.care_needs?.important_information) return null;
  return (
    <>
      <Subheading bold>
        {translations.patient.careNeeds.titleImportantInformationCare}
      </Subheading>
      <BodyWithBreak faxPrint={faxPrint}>
        {patient.care_needs.important_information}
      </BodyWithBreak>
    </>
  );
}

function Dysphagia({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { dysphagia } = patient.diagnosis;
  return dysphagia ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.dysphagia.label}
      text={dysphagia}
      column
    />
  ) : null;
}

function Allergies({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { allergies } = patient.diagnosis;
  return allergies ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalDiagnosis.allergies}
      text={allergies}
      multiline={false}
    />
  ) : null;
}

function IcdCodesForPrint({
  patient,
  translations,
}: {
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { additional_icd_codes, icd_code } = patient.diagnosis;
  if (!icd_code && !additional_icd_codes) return null;
  return (
    <IcdCodesCommon
      translations={translations}
      value={{
        icd_code,
        additional_icd_codes,
      }}
    />
  );
}

export function CoronaVirusTesting({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  const value: CoronaTest = {
    corona: patient?.diagnosis?.corona,
    corona_state: patient?.diagnosis?.corona_state,
  };
  const exists: boolean =
    !!patient?.diagnosis?.corona_state ||
    !!patient?.diagnosis?.corona?.test_result ||
    !!patient?.diagnosis?.corona?.description ||
    !!patient?.diagnosis?.corona?.vaccinated;

  if (!exists) return null;

  const { label } = coronaSwitch(translations, value);
  const vaccinated = value?.corona?.vaccinated;
  return (
    <>
      <Subheading bold>{translations.coronaTesting.auctionLabel}</Subheading>
      <BodyWithBreak faxPrint={faxPrint}>
        {label &&
          `${translations.coronaTesting.results}${translations.general.colon} ${label}`}
      </BodyWithBreak>
      {(value?.corona_state === CORONA_NOT_TESTED ||
        value?.corona_state === CORONA_TESTED) && (
        <BodyWithBreak faxPrint={faxPrint}>
          {value?.corona?.description}
        </BodyWithBreak>
      )}

      <BodyWithBreak faxPrint={faxPrint}>
        {typeof vaccinated === "string"
          ? `${translations.coronaTesting.hasBeenVaccinated}${
              vaccinated ? ` : ${vaccinated}` : ""
            }`
          : translations.coronaTesting.vaccinationUnknown}
      </BodyWithBreak>
    </>
  );
}

function InfectionAndGerms({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { infection_and_germs, infection_and_germs_state } = patient.diagnosis;
  const [negativeState, negativeStateMessage] =
    isInfectionsAndGermsPredicamentNegative(
      { infection_and_germs_state },
      translations,
    );
  if (negativeState) {
    return (
      <>
        <Subheading bold>
          {translations.patient.medicalDiagnosis.infections}
        </Subheading>
        <BodyWithBreak faxPrint={faxPrint}>
          {negativeStateMessage}
        </BodyWithBreak>
      </>
    );
  }

  if (!infection_and_germs) {
    return null;
  }

  return (
    <div>
      <Subheading bold>
        {translations.patient.medicalDiagnosis.infections}
      </Subheading>
      <BooleanField
        value={infection_and_germs.requires_isolation}
        label={
          translations.patient.medicalDiagnosis.infectionsRequiresIsolation
        }
      />
      <BooleanField
        value={infection_and_germs.mrsa}
        label={translations.patient.medicalDiagnosis.infectionsMRSA}
      />
      <BooleanField
        value={infection_and_germs.clostridien}
        label={translations.patient.medicalDiagnosis.infectionsClostridien}
      />
      <BooleanField
        value={infection_and_germs.three_mrgn}
        label={translations.patient.medicalDiagnosis.infectionsThreeMRGN}
      />
      <BooleanField
        value={infection_and_germs.four_mrgn}
        label={translations.patient.medicalDiagnosis.infectionsFourMRGN}
      />
      <BooleanField
        value={infection_and_germs.vre}
        label={translations.patient.medicalDiagnosis.infectionsVRE}
      />
      <StringField
        value={infection_and_germs.remarks}
        testId="infection_and_germs_remarks"
      />
      <StringField
        value={infection_and_germs.other}
        testId="infection_and_germs_other"
      />
    </div>
  );
}

function Addictions({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { addictions } = patient.diagnosis;
  return addictions ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.medicalDiagnosis.addictions}
      text={addictions}
      column
    />
  ) : null;
}

function PatientOrientation({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health) return null;
  const { orientation } = patient.diagnosis.mental_health;
  return orientation && orientation != 0 ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.ontologies.orientation.key}
      text={getOntology({ type: ONTOLOGY_ORIENTATION, key: orientation })}
      column
    />
  ) : null;
}

function PatientCommunication({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health) return null;
  const { communication } = patient.diagnosis.mental_health;
  return communication && communication != 0 ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.ontologies.communication.key}
      text={getOntology({
        type: ONTOLOGY_COMMUNICATION,
        key: communication,
      })}
      column
    />
  ) : null;
}

function PatientMotivation({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health) return null;
  const { motivation } = patient.diagnosis.mental_health;
  return motivation && motivation != 0 ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.ontologies.motivation.key}
      text={getOntology({ type: "motivation", key: motivation })}
      column
    />
  ) : null;
}

function Dementia({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health) return null;
  const { dementia_description } = patient.diagnosis.mental_health;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.mentalState.dementia.label}
      empty={!dementia_description}
      translations={translations}
    >
      <BodyWithBreak faxPrint={faxPrint}>{dementia_description}</BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function RunAway({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health) return null;
  const { run_away_description } = patient.diagnosis.mental_health;
  return run_away_description ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.closedAreaNeeded}
      text={run_away_description}
      column
    />
  ) : null;
}

function Nightwatch({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health) return null;
  const { night_watch_description } = patient.diagnosis.mental_health;
  return night_watch_description ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.mentalState.nightwatch.label}
      text={night_watch_description}
      column
    />
  ) : null;
}

function FacilityCharacteristics({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.mental_health) return null;
  const { closed_station, fixation } = patient.diagnosis.mental_health;
  if (!closed_station && !fixation) return null;

  return (
    <div>
      <Subheading bold>
        {translations.patient.medicalDiagnosis.hospitalStay}
      </Subheading>
      {closed_station && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.mentalState.closedStationRequired}
        </BodyWithBreak>
      )}
      {fixation && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.mentalState.fixationRequired}
        </BodyWithBreak>
      )}
    </div>
  );
}

function MentalIssues({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { mental_health } = patient.diagnosis;
  if (!mental_health) return null;
  return (
    <div>
      <Subheading bold>
        {translations.patient.mentalState.mentalIssues}
      </Subheading>
      {mental_health.depression && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.mentalState.depression}
        </BodyWithBreak>
      )}
      {mental_health.anxiety && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.mentalState.anxiety}
        </BodyWithBreak>
      )}
      {mental_health.aggressivity && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.mentalState.aggressivity}
        </BodyWithBreak>
      )}
      {mental_health.dangerous && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.mentalState.dangerous}
        </BodyWithBreak>
      )}
      {mental_health.mental_issues && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.mentalState.mentalIssuesOther}: ${mental_health.mental_issues}`}</BodyWithBreak>
      )}
    </div>
  );
}

function PatientMobility({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities) return null;
  const { mobility, mobility_additional_information } =
    patient.diagnosis.physical_disabilities;
  if (!mobility || mobility <= 0) return null;
  return (
    <div>
      <Subheading bold>{translations.ontologies.mobility.key}</Subheading>
      <BodyWithBreak faxPrint={faxPrint}>
        {getOntology({ type: ONTOLOGY_MOBILITY, key: mobility })}
      </BodyWithBreak>
      {mobility_additional_information && (
        <BodyWithBreak faxPrint={faxPrint}>
          {mobility_additional_information}
        </BodyWithBreak>
      )}
    </div>
  );
}

function VisualImpairment({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities) return null;
  const { visual_impairment } = patient.diagnosis.physical_disabilities;
  return visual_impairment ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.mobility.visual_impairment.label}
      text={visual_impairment}
      column
    />
  ) : null;
}

function AuditiveImpairment({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities) return null;
  const { auditive_impairment } = patient.diagnosis.physical_disabilities;
  return auditive_impairment ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.mobility.auditive_impairment.label}
      text={auditive_impairment}
      column
    />
  ) : null;
}

function AssistiveEquipment({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities) return null;

  const {
    assistive_equipment,
    existing_medical_supplies,
    prescribed_medical_supplies,
    required_medical_supplies,
  } = patient.diagnosis.physical_disabilities;

  const exists =
    !!assistive_equipment ||
    !!required_medical_supplies ||
    !!prescribed_medical_supplies ||
    !!existing_medical_supplies;
  const newValues =
    !!required_medical_supplies ||
    !!prescribed_medical_supplies ||
    !!existing_medical_supplies;
  return (
    <>
      {exists && (
        <Subheading bold>
          {translations.patient.mobility.assistiveEquipment}
        </Subheading>
      )}
      {assistive_equipment && !newValues && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${assistive_equipment}`}</BodyWithBreak>
      )}
      {existing_medical_supplies && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.medicalSupplies.existingMedicalSupplies}: ${existing_medical_supplies}`}</BodyWithBreak>
      )}
      {prescribed_medical_supplies && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.medicalSupplies.prescribedMedicalSupplies}: ${prescribed_medical_supplies}`}</BodyWithBreak>
      )}
      {required_medical_supplies && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.medicalSupplies.neededMedicalSupplies}: ${required_medical_supplies}`}</BodyWithBreak>
      )}
    </>
  );
}

function Orthosis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities) return null;
  const { orthese } = patient.diagnosis.physical_disabilities;
  return orthese ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.mobility.orthese.label}
      text={orthese}
      column
    />
  ) : null;
}

function Prothesis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis?.physical_disabilities) return null;
  const { prothese } = patient.diagnosis.physical_disabilities;
  return prothese ? (
    <BasicCategory
      faxPrint={faxPrint}
      title={translations.patient.mobility.prothese.label}
      text={prothese}
      column
    />
  ) : null;
}

function HospitalStay({
  faxPrint,
  forClinic,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  forClinic?: boolean;
  patient: Patient;
  translations: Translations;
}) {
  const locale = useLocale();
  if (!patient.diagnosis) return null;
  const { hospital_stay } = patient.diagnosis;
  if (!hospital_stay) return null;
  return (
    <div>
      <Subheading bold>
        {translations.patient.medicalDiagnosis.hospitalStay}
      </Subheading>
      {forClinic && (
        <>
          {hospital_stay.doctor_in_charge_in_hospital && (
            <BodyWithBreak faxPrint={faxPrint}>
              {`${translations.patient.medicalDiagnosis.doctorInChargeInHospital}: ${hospital_stay.doctor_in_charge_in_hospital}`}
            </BodyWithBreak>
          )}
          {hospital_stay.doctor_in_charge_in_hospital_phone && (
            <BodyWithBreak faxPrint={faxPrint}>
              {`${translations.patient.stationPhoneNumber}: ${hospital_stay.doctor_in_charge_in_hospital_phone}`}
            </BodyWithBreak>
          )}
        </>
      )}
      {hospital_stay.operation_date && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${
            translations.patient.medicalDiagnosis.operationDate
          }: ${formatUnixDate(hospital_stay.operation_date, locale)}`}
        </BodyWithBreak>
      )}
      {hospital_stay.operation_description && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.medicalDiagnosis.operationDescription}: ${hospital_stay.operation_description}`}
        </BodyWithBreak>
      )}
      {hospital_stay.geriatric_early_stage && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.medicalDiagnosis.conductedEarlyRehab}:
          {hospital_stay.geriatric_early_stage}
        </BodyWithBreak>
      )}
    </div>
  );
}

function RehabilitationPotential({
  faxPrint,
  getOntology,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  getOntology: GetOntologyType;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { rehabilitation_potential } = patient.diagnosis;
  if (!rehabilitation_potential) return null;
  return (
    <div>
      <Subheading bold>
        {translations.patient.medicalDiagnosis.rehabilitationPotential}
      </Subheading>
      {rehabilitation_potential.evaluation != null && (
        <BodyWithBreak faxPrint={faxPrint}>
          {getOntology({
            type: "rehabilitationPotential",
            key: rehabilitation_potential.evaluation,
          })}
        </BodyWithBreak>
      )}
      {rehabilitation_potential.description && (
        <BodyWithBreak faxPrint={faxPrint}>
          {rehabilitation_potential.description}
        </BodyWithBreak>
      )}
    </div>
  );
}

function LimitedCapacity({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient?.diagnosis) return null;
  const { limited_capacity } = patient.diagnosis;
  if (
    !limited_capacity ||
    !activableInputCollectionIsNotEmpty(limited_capacity)
  ) {
    return null;
  }

  return (
    <div>
      <Subheading bold>
        {translations.patient.medicalDiagnosis.limitations}
      </Subheading>
      {limited_capacity.limited_psychological_capacity && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.medicalDiagnosis.limitedPsychologicalCapacity}: ${limited_capacity.limited_psychological_capacity}`}
        </BodyWithBreak>
      )}
      {limited_capacity.limited_cognitive_capacity && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.medicalDiagnosis.limitedCognitiveCapacity}: ${limited_capacity.limited_cognitive_capacity}`}
        </BodyWithBreak>
      )}
      {limited_capacity.limited_social_behaviour && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.medicalDiagnosis.limitedSocialBehaviour}: ${limited_capacity.limited_social_behaviour}`}
        </BodyWithBreak>
      )}
      {limited_capacity.limited_physical_capacity && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.medicalDiagnosis.limitedPsychicalCapacity}: ${limited_capacity.limited_physical_capacity}`}
        </BodyWithBreak>
      )}
      {limited_capacity.other && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.medicalDiagnosis.limitedCapacityOther}: ${limited_capacity.other}`}
        </BodyWithBreak>
      )}
    </div>
  );
}

function RehabilitationGoals({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.diagnosis) return null;
  const { rehabilitation_goals } = patient.diagnosis;
  return (
    rehabilitation_goals && (
      <BasicCategory
        faxPrint={faxPrint}
        title={translations.patient.medicalDiagnosis.rehabilitationGoals}
        text={rehabilitation_goals}
        column
      />
    )
  );
}

function Nutrition({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { nutrition } = patient.care_needs;
  if (!nutrition) return null;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.nutrition}
      empty={
        nutrition.parenteral_nutrition == null &&
        nutrition.enteral_nutrition == null
      }
      translations={translations}
    >
      {nutrition.parenteral_nutrition != null && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.careNeeds.parenteralNutrition.label}: ${nutrition.parenteral_nutrition}`}</BodyWithBreak>
      )}
      {nutrition.enteral_nutrition != null && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.careNeeds.enteralNutrition.label}: ${nutrition.enteral_nutrition}`}</BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function Incontinence({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { incontinence } = patient.care_needs;
  if (!incontinence) return null;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.incontinence}
      empty={
        incontinence.urinary_incontinence == null &&
        incontinence.fecal_incontinence == null
      }
      translations={translations}
    >
      {incontinence.urinary_incontinence != null && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.careNeeds.urinaryIncontinence}: ${incontinence.urinary_incontinence}`}</BodyWithBreak>
      )}
      {incontinence.fecal_incontinence != null && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.careNeeds.fecalIncontinence}: ${incontinence.fecal_incontinence}`}</BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function Breathing({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { breathing } = patient.care_needs;
  if (!breathing) return null;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.breathing}
      empty={
        breathing.tracheostomy == null &&
        !breathing.specialised_intensive_nurse &&
        breathing.oxygen_therapy == null
      }
      translations={translations}
    >
      {breathing.tracheostomy != null && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.careNeeds.tracheostomy.label}: ${breathing.tracheostomy}`}</BodyWithBreak>
      )}
      {breathing.specialised_intensive_nurse && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.ontologies.service.values.specialisedIntensiveNurse}
        </BodyWithBreak>
      )}
      {breathing.oxygen_therapy != null && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.careNeeds.oxygenTherapy.label}: ${breathing.oxygen_therapy}`}</BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function Dialysis({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { dialysis } = patient.care_needs;
  if (!dialysis) return null;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.dialysis}
      empty={
        dialysis.hemodialysis == null && dialysis.peritoneal_dialysis == null
      }
      translations={translations}
    >
      {dialysis.hemodialysis != null && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.careNeeds.hemodialysis.label}: ${dialysis.hemodialysis}`}</BodyWithBreak>
      )}
      {dialysis.peritoneal_dialysis != null && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.careNeeds.peritonealDialysis.label}: ${dialysis.peritoneal_dialysis}`}</BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function Wounds({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { wound_care } = patient.care_needs;
  if (!wound_care) return null;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.woundCare}
      empty={!wound_care.description}
      translations={translations}
    >
      <BodyWithBreak faxPrint={faxPrint}>
        {wound_care.description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Support({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { support } = patient.care_needs;
  if (!support) return null;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.needSupport}
      empty={!support.eating && !support.body_care && !support.dressing}
      translations={translations}
    >
      {support.eating && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.careNeeds.withEating}
        </BodyWithBreak>
      )}
      {support.body_care && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.careNeeds.withBodyCare}
        </BodyWithBreak>
      )}
      {support.dressing && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.careNeeds.withDressing}
        </BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function PsychiatricCare({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { psychiatric_care } = patient.care_needs;
  if (!psychiatric_care) return null;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.psychiatric_care.label}
      empty={!psychiatric_care.description}
      translations={translations}
    >
      <BodyWithBreak faxPrint={faxPrint}>
        {psychiatric_care.description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Medication({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { medication } = patient.care_needs;

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.medication}
      empty={!medication}
      translations={translations}
    >
      <BodyWithBreak faxPrint={faxPrint}>
        {medication?.description}
      </BodyWithBreak>
      {medication?.blood_thinner && (
        <BodyWithBreak faxPrint={faxPrint}>
          {`${translations.patient.takesBloodThinner}${translations.general.colon} ${medication.blood_thinner}`}
        </BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function Compression({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { compression } = patient.care_needs;
  if (!compression) return null;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.compression}
      empty={!compression.description}
      translations={translations}
    >
      <BodyWithBreak faxPrint={faxPrint}>
        {compression.description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Injection({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { injection } = patient.care_needs;
  if (!injection) return null;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.injection}
      empty={!injection.description}
      translations={translations}
    >
      <BodyWithBreak faxPrint={faxPrint}>{injection.description}</BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function AccessDrainages({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  const { access_drainages } = patient.care_needs || {
    access_drainages: undefined,
  };
  if (!access_drainages) return null;
  const keys = [
    "tracheal_cannula",
    "gastric_tube",
    "central_venous_catheter",
    "peripheral_venous_catheter",
    "suprapubic_catheter",
    "permanent_catheter",
    "drainage",
    "intravenous_injection",
    "other_access_drainages",
  ];

  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.accessDrainages.accessAndDrainages}
      empty={!keys.find((attr) => (access_drainages as ToType)[attr] != null)}
      translations={translations}
    >
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={translations.patient.careNeeds.accessDrainages.trachealCannula}
        value={access_drainages.tracheal_cannula}
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={translations.patient.careNeeds.accessDrainages.gastricTube}
        value={access_drainages.gastric_tube}
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={
          translations.patient.careNeeds.accessDrainages.centralVenousCatheter
        }
        value={access_drainages.central_venous_catheter}
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={
          translations.patient.careNeeds.accessDrainages
            .peripheralVenousCatheter
        }
        value={access_drainages.peripheral_venous_catheter}
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={
          translations.patient.careNeeds.accessDrainages.suprapubicCatheter
        }
        value={access_drainages.suprapubic_catheter}
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={
          translations.patient.careNeeds.accessDrainages.permanentCatheter
        }
        value={access_drainages.permanent_catheter}
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={translations.patient.careNeeds.accessDrainages.drainage}
        value={access_drainages.drainage}
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={translations.patient.intravenousInjection}
        value={access_drainages.intravenous_injection}
      />
      <EmptiablePrintField
        faxPrint={faxPrint}
        prefix={translations.patient.careNeeds.accessDrainages.other}
        value={access_drainages.other_access_drainages}
      />
    </EmptyablePrintCategory>
  );
}

function BaseCare({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { base_care } = patient.care_needs;
  if (!base_care) return null;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.baseCare}
      empty={!base_care.description && !base_care.companionship_grant}
      translations={translations}
    >
      <BodyWithBreak faxPrint={faxPrint}>{base_care.description}</BodyWithBreak>
      {base_care.companionship_grant && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.careNeeds.carePaidByHealthInsurance}
        </BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function MedicalCare({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.medicalCare}
      empty={!patient.care_needs?.basic_medical_care?.description}
      translations={translations}
    >
      <BodyWithBreak faxPrint={faxPrint}>
        {patient.care_needs?.basic_medical_care?.description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Prescription({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.assessments.medicalSupplies.prescriptionIssued}
      empty={!auction.medical_supplies?.prescription_issued}
      translations={translations}
    >
      <BodyWithBreak faxPrint={faxPrint}>
        {auction.medical_supplies?.prescription_issued_description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Destination({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  if (!auction.profile?.search_destination || !auction.profile?.search_location)
    return null;
  const pickupLocationFormatted = formatLocation({
    location: auction.profile.search_location,
    translations,
    showFloor: true,
  });
  const destinationocationFormatted = formatLocation({
    location: auction.profile.search_destination,
    translations,
    showFloor: true,
  });
  return (
    <>
      {pickupLocationFormatted ? (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.address.pickupAddress}: ${pickupLocationFormatted}`}</BodyWithBreak>
      ) : null}
      {destinationocationFormatted ? (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.assessments.transportSearch.searchDestination}: ${destinationocationFormatted}`}</BodyWithBreak>
      ) : null}
    </>
  );
}

function Products({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.assessments.medicalSupplies.medicalSupplies}
      empty={!auction.medical_supplies?.products?.length}
      translations={translations}
    >
      {auction.medical_supplies?.products?.map(
        (product: MedicalProductType) => (
          <BodyWithBreak key={product.id} faxPrint={faxPrint}>
            {formatProductString(product, translations)}
          </BodyWithBreak>
        ),
      )}
      {auction.medical_supplies?.additional_information && (
        <BodyWithBreak faxPrint={faxPrint}>
          {auction.medical_supplies?.additional_information}
        </BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function PickUpSection({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  const { currentLocale } = useDateLocale();
  const pickUpTimeFormatted = formatPickupStringValue(auction, currentLocale);
  return (
    <>
      {pickUpTimeFormatted ? (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.assessments.transportSearch.pickUpTime}: ${pickUpTimeFormatted}`}</BodyWithBreak>
      ) : null}
    </>
  );
}

function DeliveryDetails({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  const { deliveryAddressPrefix, deliveryAddressString } = locationValue(
    auction,
    translations,
  );
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.assessments.medicalSupplies.deliveryDetails}
      empty={!auction.medical_supplies?.delivery_address_type}
      translations={translations}
    >
      {deliveryAddressString && (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${deliveryAddressPrefix}: ${deliveryAddressString}`}</BodyWithBreak>
      )}
    </EmptyablePrintCategory>
  );
}

function TransportEquipment({
  auction,
  faxPrint,
  translations,
}: {
  auction: Auction;
  faxPrint: boolean | null | undefined;
  translations: Translations;
}) {
  return (
    <>
      {auction?.profile?.no_site_support_required ? (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.assessments.transportSearch.onsiteSupportRequired}`}</BodyWithBreak>
      ) : null}
      {auction?.profile?.needs_oxygen ? (
        <BodyWithBreak faxPrint={faxPrint}>{`${
          translations.assessments.transportSearch.oxygenNeeded
        }: ${
          auction?.profile?.needs_oxygen
            ? translations.actions.yes
            : translations.actions.no
        }`}</BodyWithBreak>
      ) : null}
      {auction?.patient?.care_needs?.remarks ? (
        <BodyWithBreak
          faxPrint={faxPrint}
        >{`${translations.patient.additionalInformation}: ${auction?.patient?.care_needs?.remarks}`}</BodyWithBreak>
      ) : null}
    </>
  );
}

function Companionship({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { companionship } = patient.care_needs;
  if (!companionship) return null;
  return (
    <div>
      <Subheading bold>
        {translations.patient.careNeeds.companionship}
      </Subheading>
      <BodyWithBreak faxPrint={faxPrint}>
        {companionship.description}
      </BodyWithBreak>
      {companionship.companionship_grant && (
        <BodyWithBreak faxPrint={faxPrint}>
          {translations.patient.careNeeds.carePaidByHealthInsurance}
        </BodyWithBreak>
      )}
    </div>
  );
}

function HelpAtHome({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { help_at_home } = patient.care_needs;
  if (!help_at_home) return null;
  return (
    <EmptyablePrintCategory
      faxPrint={faxPrint}
      title={translations.patient.careNeeds.helpAtHome}
      empty={!help_at_home.description}
      translations={translations}
    >
      <BodyWithBreak faxPrint={faxPrint}>
        {help_at_home.description}
      </BodyWithBreak>
    </EmptyablePrintCategory>
  );
}

function Remarks({
  faxPrint,
  patient,
  translations,
}: {
  faxPrint: boolean | null | undefined;
  patient: Patient;
  translations: Translations;
}) {
  if (!patient.care_needs) return null;
  const { remarks } = patient.care_needs;
  if (!remarks) return null;
  return (
    <div>
      <Subheading bold>{translations.patient.careNeeds.remarks}</Subheading>
      {remarks && <BodyWithBreak faxPrint={faxPrint}>{remarks}</BodyWithBreak>}
    </div>
  );
}

type Category = { Component: ToType; key: string };
const careneedsComponents: Array<Category> = [
  {
    Component: Support,
    key: "support",
  },
  {
    Component: PsychiatricCare,
    key: "psychiatric_care",
  },
  {
    Component: PalliativeCare,
    key: "palliative_care",
  },
  {
    Component: Nutrition,
    key: "nutrition",
  },
  {
    Component: Incontinence,
    key: "incontinence",
  },
  {
    Component: Breathing,
    key: "breathing",
  },
  {
    Component: Dialysis,
    key: "Dialysis",
  },
  {
    Component: Wounds,
    key: "Wounds",
  },
  {
    Component: Medication,
    key: "medication",
  },
  {
    Component: Compression,
    key: "compression",
  },
  {
    Component: Injection,
    key: "injection",
  },
  {
    Component: AccessDrainages,
    key: "access_drainages",
  },
  {
    Component: ImportantInformation,
    key: "important_information",
  },
  {
    Component: BaseCare,
    key: "base_care",
  },
  {
    Component: MedicalCare,
    key: "basic_medical_care",
  },
  {
    Component: Companionship,
    key: "companionship",
  },
  {
    Component: HelpAtHome,
    key: "help_at_home",
  },
  {
    Component: Remarks,
    key: "remarks",
  },
];

const transportSearchComponentMapping: any[] = [
  {
    Component: PickUpSection,
    key: "pick_up",
  },

  {
    Component: Destination,
    key: "destination",
  },
  {
    Component: TransportEquipment,
    key: "transport_equipment",
  },
];

const medicalDiagnosisComponents: Array<Category> = [
  {
    Component: IcdCodesForPrint,
    key: "icd_codes",
  },
  {
    Component: InfectionAndGerms,
    key: "infection_and_germs",
  },
  {
    Component: CoronaVirusTesting,
    key: "corona_testing",
  },
  {
    Component: DependencyDiagnosis,
    key: "dependencyDiagnosis",
  },
  {
    Component: Dysphagia,
    key: "dysphagia",
  },
  {
    Component: ChronicPains,
    key: "chronic_pains",
  },
  {
    Component: Allergies,
    key: "allergies",
  },
  {
    Component: Addictions,
    key: "addictions",
  },
  {
    Component: HospitalStay,
    key: "hospital_stay",
  },
  {
    Component: LimitedCapacity,
    key: "limited_capacity",
  },
  {
    Component: RehabilitationGoals,
    key: "rehabilitation_goals",
  },
  {
    Component: RehabilitationPotential,
    key: "rehabilitation_potential",
  },
];

const mentalHealthComponents: Array<Category> = [
  {
    Component: PatientOrientation,
    key: "patient_orientation",
  },
  {
    Component: PatientMotivation,
    key: "patient_motivation",
  },
  {
    Component: PatientCommunication,
    key: "patient_communication",
  },
  {
    Component: Dementia,
    key: "dementia",
  },
  {
    Component: RunAway,
    key: "run_away",
  },
  {
    Component: Nightwatch,
    key: "night_watch",
  },
  {
    Component: FacilityCharacteristics,
    key: "facility_characteristics",
  },
  {
    Component: MentalIssues,
    key: "mental_issues",
  },
];

const physicalComponents: Array<Category> = [
  {
    Component: PatientMobility,
    key: "patient_mobility",
  },
  {
    Component: VisualImpairment,
    key: "visual_impairment",
  },
  {
    Component: AuditiveImpairment,
    key: "auditive_impairment",
  },
  {
    Component: AssistiveEquipment,
    key: "assistive_equipment",
  },
  {
    Component: Orthosis,
    key: "orthosis",
  },
  {
    Component: Prothesis,
    key: "prothesis",
  },
];

const generalInformationComponents: Array<Category> = [
  {
    Component: RelevantProviderInformation,
    key: "relavant_information",
  },
  {
    Component: GeneralInformation,
    key: "general_information",
  },
  {
    Component: Insurance,
    key: "insurance",
  },
  {
    Component: Carelevel,
    key: "carelevel",
  },
  {
    Component: Payout,
    key: "payout",
  },
  {
    Component: LivingSituation,
    key: "living_situation",
  },
  {
    Component: GeneralPractitioner,
    key: "general_practitioner",
  },
  {
    Component: Communication,
    key: "communication",
  },
  {
    Component: Grants,
    key: "grants",
  },
  {
    Component: Payers,
    key: "payers",
  },
  {
    Component: Preferences,
    key: "preferences",
  },
];

const medicalSuppliesSearchComponents: Array<Category> = [
  {
    Component: Products,
    key: "products",
  },
  {
    Component: Prescription,
    key: "prescription",
  },
  {
    Component: DeliveryDetails,
    key: "delivery_details",
  },
];
