import { useEnvContext } from "context/EnvContext";
import { APP_CLINIC, APP_PROVIDER_SEARCH } from "core/consts";
import {
  APP_BAR_HEIGHT,
  APP_BAR_PROVIDERSEARCH_HEIGHT,
  TABS_HEIGHT_MOBILE,
  dp,
} from "ds_legacy/materials/metrics";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { PRODUCT_TOURS, tourAttributes } from "dsl/molecules/useProductTour";
import { BANNER_WIDTH } from "dsl/organisms/Banners";
import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";

export const RESPONSIVE_BREAKPOINT_ZOOM_200 = 500;

export function getHeight({
  isProviderSearch,
  isTablet,
}: {
  isProviderSearch?: boolean;
  isTablet?: boolean;
}): CSSProperties["height"] {
  if (isTablet) {
    if (isProviderSearch) {
      return `calc(100vh - ${dp(
        APP_BAR_PROVIDERSEARCH_HEIGHT,
      )} - ${TABS_HEIGHT_MOBILE})`;
    }
    return `calc(100vh - ${dp(APP_BAR_HEIGHT)} - ${TABS_HEIGHT_MOBILE})`;
  }
  return "100%";
}

const ContentWrapper = styled.div<{
  border?: CSSProperties["border"];
  isProviderSearch?: boolean;
  isTablet?: boolean;
}>`
  position: relative;
  border: ${({ border }) => border};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: ${({ isProviderSearch, isTablet }) =>
    getHeight({ isTablet, isProviderSearch })};
  align-items: center;
  justify-content: center;
  max-width: ${({ isTablet }) => (isTablet ? "100%" : dp(BANNER_WIDTH))};
  padding: 0;
  width: 100%;
  @media (max-height: ${dp(RESPONSIVE_BREAKPOINT_ZOOM_200)}) {
    height: 100%;
  }
`;

export function MessengerLayout({ children }: { children: ReactNode }) {
  const { app } = useEnvContext();
  const { isDesktop, isTablet } = useMedia();
  const isClinic = app === APP_CLINIC;
  const isProviderSearch = app == APP_PROVIDER_SEARCH;

  return (
    <ContentWrapper
      data-testid="messenger-content"
      isTablet={isTablet}
      isProviderSearch={isProviderSearch}
      {...(isClinic && isDesktop
        ? tourAttributes({
            tourKey: PRODUCT_TOURS.share_patient_data.key,
            stepKey: PRODUCT_TOURS.share_patient_data.steps.track_changes.key,
          })
        : {})}
    >
      {children}
    </ContentWrapper>
  );
}

export default MessengerLayout;
