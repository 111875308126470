import { useGetCareproviderWarnings } from "apollo/hooks/queries";
import {
  AUCTION_STATUS_ACCEPTED,
  AUCTION_STATUS_CANCELLED,
  AUCTION_STATUS_SUCCESS,
} from "core/consts";
import { useSafeState } from "core/hooks";
import { Careprovider, QueryProgress, WarningsPayload } from "core/types";
import { Dispatch, SetStateAction, useEffect } from "react";

export const useSelectedCareproviderWarnings = ({
  auctionId,
  onOpen,
}: {
  auctionId: number;
  onOpen?: () => void;
}): {
  queryProgress: QueryProgress;
  selectedCareprovider: Careprovider | null;
  setSelectedCareprovider: Dispatch<SetStateAction<Careprovider | null>>;
  warningsPayload: WarningsPayload | undefined;
} => {
  const [selectedCareprovider, setSelectedCareprovider] =
    useSafeState<Careprovider | null>(null);

  const [queryProgress, warningsPayload] = useGetCareproviderWarnings({
    auctionId,
    careproviderId: selectedCareprovider?.id,
  });

  useEffect(() => {
    if (
      warningsPayload?.careprovider &&
      warningsPayload?.careprovider.id === selectedCareprovider?.id
    ) {
      setSelectedCareprovider(warningsPayload?.careprovider);
      onOpen?.();
    } else {
      setSelectedCareprovider(null);
    }
  }, [warningsPayload?.careprovider?.id]);

  return {
    selectedCareprovider,
    setSelectedCareprovider,
    warningsPayload,
    queryProgress,
  };
};

export const shouldDisableActions = (
  auctionStatus: number | undefined,
): boolean => {
  if (!auctionStatus) return false;
  return [
    AUCTION_STATUS_SUCCESS,
    AUCTION_STATUS_CANCELLED,
    AUCTION_STATUS_ACCEPTED,
  ].includes(auctionStatus);
};
