import { Snackbar } from "@mui/material";
import { useA11yMessage } from "containers/A11yStatusProvider/A11yStatusContext";
import { Notification } from "core/types";
import { InfoBanner } from "ds_legacy/components/InfoBanner";
import {
  ComponentType,
  ReactNode,
  Reducer,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";

const NotificationContext = createContext<(options: Notification) => void>(
  () => {},
);

export function withNotification<P>(
  WrappedComponent: ComponentType<
    P & { showNotification: (options: Notification) => void }
  >,
) {
  const ComponentWithNotification = (props: P) => {
    return (
      <NotificationContext.Consumer>
        {(showNotification) => (
          <WrappedComponent
            {...(props as unknown as P)}
            showNotification={showNotification}
          />
        )}
      </NotificationContext.Consumer>
    );
  };
  return ComponentWithNotification;
}

export function useNotification() {
  return useContext(NotificationContext);
}

export type NotificationReducer = {
  activeNotification: Notification | undefined;
  counter: number;
  notifications: Array<Notification>;
};

export const ADD_NOTIFICATION = "add" as const;
export const NEXT_NOTIFICATION = "next" as const;

const defaultNotificationState: NotificationReducer = {
  counter: 0,
  notifications: [],
  activeNotification: undefined,
};

type NotificationReducerActions =
  | { payload: Notification; type: typeof ADD_NOTIFICATION }
  | { type: typeof NEXT_NOTIFICATION };

export function notificationReducer(
  state: NotificationReducer,
  action: NotificationReducerActions,
): NotificationReducer {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const notification: Notification = {
        ...action.payload,
        a11yType: action.payload?.a11yType ?? "status",
        key: `notification-${state.counter}`,
      };
      if (state.activeNotification == undefined) {
        return {
          ...state,
          activeNotification: notification,
        };
      }

      if (
        state.activeNotification.message !== notification.message &&
        !state.notifications.find((n) => n.message === notification.message)
      ) {
        return {
          ...state,
          counter: state.counter + 1,
          notifications: [...state.notifications, action.payload],
        };
      }
      return state;
    }
    case NEXT_NOTIFICATION: {
      if (state.notifications.length > 0) {
        const newNotifications = state.notifications;
        const activeNotification = newNotifications.shift();
        return {
          counter: state.counter - 1,
          notifications: newNotifications,
          activeNotification,
        };
      }
      return {
        ...state,
        activeNotification: undefined,
      };
    }
    default: {
      console.error("[NOTIFICATION] Reducer action not found", {
        state,
        action,
      });
      return state;
    }
  }
}

export default function NotificationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [{ activeNotification }, dispatch] = useReducer<
    Reducer<NotificationReducer, NotificationReducerActions>
  >(notificationReducer, defaultNotificationState);

  const showNotification = useCallback(
    (payload: Notification) => dispatch({ type: ADD_NOTIFICATION, payload }),
    [dispatch],
  );

  const nextNotification = useCallback(() => {
    dispatch({ type: NEXT_NOTIFICATION });
  }, [dispatch]);

  const { updateA11yMessage } = useA11yMessage();

  useEffect(() => {
    if (activeNotification?.message.length) {
      updateA11yMessage(
        activeNotification.message,
        activeNotification?.a11yType,
      );
    }
  }, [activeNotification?.message]);

  const Component = activeNotification?.Component;

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
      {!!Component && <Component nextNotification={nextNotification} />}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={8000}
        key={activeNotification?.key}
        onClose={nextNotification}
        open={!!activeNotification && !Component}
      >
        <div>
          <InfoBanner
            message={activeNotification?.message}
            onClose={nextNotification}
            severity={activeNotification?.type}
            testId="notification-message"
            actions={activeNotification?.actions}
          />
        </div>
      </Snackbar>
    </NotificationContext.Provider>
  );
}
