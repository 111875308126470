import { LIGHT_GREY } from "ds_legacy/materials/colors";
import styled from "styled-components";

export const SkeletonRectangle = styled.div<{
  height?: string;
  width?: string;
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${LIGHT_GREY};
`;

export const SkeletonCircle = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${LIGHT_GREY};
`;
