import { Careseeker } from "core/types";
import lazy from "dsl/atoms/retry";
import { Suspense } from "react";

const LazyExternalIdKisField = lazy(() => import("./index"));

export default function ExternalIdKisField(props: {
  careseeker: Careseeker;
  externalIdQueryVar?: string;
  initialPatientId?: string;
  textLabelWidth: string;
}) {
  return (
    <Suspense fallback={<div />}>
      <LazyExternalIdKisField {...props} />
    </Suspense>
  );
}
