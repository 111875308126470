import CircularProgress from "@mui/material/CircularProgress";
import { useWebSocketContext } from "apollo/client/WebSocketContext";
import { useLazyGetAuction } from "apollo/hooks/queries";
import { useOnCandidatesCalculated } from "apollo/hooks/subscriptions";
import {
  CANDIDATES_STATUS_PENDING,
  CANDIDATES_STATUS_SUCCESS,
  TRACK_EVENTS,
} from "core/consts";
import { useDateLocale } from "core/hooks/useDateLocale";
import {
  ONTOLOGY_SOLUTION,
  ONTOLOGY_SPECIALIZATIONS,
  OntologyType,
} from "core/model/utils/ontologies";
import { useGetOntology } from "core/model/utils/ontologies/hooks";
import { Auction, GetOntologyType } from "core/types";
import { format, fromUnixTime, isValid } from "date-fns";
import { getOrderedItems } from "ds_legacy/components/ChipGroup";
import RSButton from "ds_legacy/components/RSButton";
import {
  APP_BACKGROUND,
  CUSTOM_BLACK,
  GREY_600,
  WHITE,
} from "ds_legacy/materials/colors";
import { HorizontalLayout, VerticalLayout } from "ds_legacy/materials/layouts";
import { dp, margin, padding, sizing } from "ds_legacy/materials/metrics";
import {
  Body,
  FONT_SIZE_16,
  FONT_WEIGHT_REGULAR,
  Heading,
  LINE_HEIGHT_20,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import { useCandidates } from "dsl/atoms/SearchCandidates";
import { useRefreshComponent } from "dsl/hooks";
import { useCareseekerNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import { ClinicPatientInfos } from "dsl/organisms/Infos/PatientInformation";
import PrintPatientInfo from "dsl/organisms/Infos/PatientInformation/PrintPatientInfo";
import { solutionSortOrder } from "dsl/organisms/PatientCreatePage/PatientCreateForm";
import { useExtracted } from "dsl/organisms/PatientMenu";
import { FieldsHolderType } from "dsl/organisms/PatientMenu/transform";
import PatientNavigation from "dsl/organisms/PatientNavigation";
import { useEffect } from "react";
import { useTracking } from "react-tracking";
import styled from "styled-components";
import { useTranslations } from "translations";
import Translations from "translations/types";
import PatientProfileStatusBanner from "./PatientProfileStatusBanner";

const CTA_WIDTH = 298;
const TRANSFER_DETAILS_WIDTH = 359;
const TRANSFER_DETAILS_CONTENT_WIDTH = 327;

const TransferDetailsWrapper = styled.div`
  background: ${WHITE};
  width: ${dp(TRANSFER_DETAILS_WIDTH)};
  box-sizing: border-box;
  box-shadow: ${dp(0)} ${dp(4)} ${dp(11)} rgba(153, 153, 153, 0.1);
  border-radius: ${dp(4)};
`;

const TransferDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: ${dp(TRANSFER_DETAILS_CONTENT_WIDTH)};
`;

const DetailsWrapper = styled.div`
  overflow: unset;
  height: 100%;
`;

const StickyDetailsWrapper = styled.div`
  width: ${dp(TRANSFER_DETAILS_WIDTH)};
  position: sticky;
  top: ${dp(220)};
  left: 0;
`;

const PatientProfileWrapper = styled(HorizontalLayout)`
  box-sizing: border-box;
  padding: ${padding(3)};
  justify-content: center;
  width: 100%;
  min-height: 60vh;
  height: 100%;
  overflow: unset;
  gap: ${sizing(3)};
  background: ${APP_BACKGROUND};
`;

export const getSelectedOntologies = (
  values: number[],
  type: OntologyType,
  getOntology: GetOntologyType,
  sortOrder?: AnyObject,
) => {
  if (values) {
    // #TODO - check how getOrderedItems is working here. It seems like values (number[]) is the wrong format
    const items = sortOrder ? getOrderedItems(sortOrder, values) : values;
    const res = items.map((value: number) =>
      getOntology({
        type,
        key: value,
      }),
    );
    return res.join(", ");
  }
  return undefined;
};

export const useCandidatesSynchronizer = ({
  auction,
}: {
  auction: Auction;
}) => {
  const getAuction = useLazyGetAuction();

  const skip =
    !auction.assessment_completed ||
    auction.candidates_status === CANDIDATES_STATUS_SUCCESS;

  const { isAlive } = useWebSocketContext();

  // subscribes to websocket, updates auction when candidates are calculated
  useOnCandidatesCalculated(auction.id, { skip });

  const timeTillRefetch = isAlive !== false ? 5 * 1000 : 2 * 1000;

  // if response not received in {timeTillRefetch} seconds, fetch auction from DB
  const refetchCount = useRefreshComponent(skip ? null : timeTillRefetch);

  useEffect(() => {
    if (!refetchCount || skip) return;

    console.warn(`refetched auction ${auction.id} for candidates status`, {
      isAlive,
      refetchCount,
    });

    getAuction(auction.id);
  }, [skip, refetchCount]);
};

export const getSearchRadiusTranslation = ({
  auction,
  extracted,
  translations,
}: {
  auction: Auction;
  extracted: FieldsHolderType;
  translations: Translations;
}): string | undefined => {
  if (auction?.country_wide_search) {
    return translations.patient.countrySearch;
  }
  if (extracted.radius_in_km) {
    return `${extracted.radius_in_km} km`;
  }
  return undefined;
};

const TransferDetailRow = ({
  label,
  testId,
  value,
}: {
  label: string;
  testId?: string;
  value: number | string | null | undefined;
}) => {
  if (value == null) {
    return null;
  }

  return (
    <HorizontalLayout margin={margin(1, 0)}>
      <Body
        fontWeight={FONT_WEIGHT_REGULAR}
        fontSize={FONT_SIZE_16}
        color={CUSTOM_BLACK}
        margin={margin(0)}
        data-testid={testId}
        display="inline-block"
      >
        <Body
          fontWeight={FONT_WEIGHT_REGULAR}
          fontSize={FONT_SIZE_16}
          color={GREY_600}
          margin={margin(0, 0.5, 0, 0)}
          style={{ whiteSpace: "nowrap" }}
          display="inline-block"
        >
          {label}
        </Body>
        {value}
      </Body>
    </HorizontalLayout>
  );
};

export function TransferDetails({ auction }: { auction: Auction }) {
  const getOntology = useGetOntology();
  const translations = useTranslations();
  const { currentLocale: locale } = useDateLocale();
  const navigationHandlers = useCareseekerNavigationHandlers();
  const extracted = useExtracted(auction);
  const potentialReceivers = useCandidates().candidateContext.candidates;
  const { trackEvent } = useTracking();

  const typeOfTransfer = getSelectedOntologies(
    extracted.solutions,
    ONTOLOGY_SOLUTION,
    getOntology,
    solutionSortOrder,
  );
  const specialisation = getSelectedOntologies(
    extracted.specializations,
    ONTOLOGY_SPECIALIZATIONS,
    getOntology,
  );
  const formattedDate = format(fromUnixTime(extracted.start_date), "P", {
    locale,
  });
  const startDate = isValid(extracted.start_date) ? formattedDate : undefined;
  const treatmentLocation = extracted.zipcode;
  const searchRadius = getSearchRadiusTranslation({
    auction,
    translations,
    extracted,
  });

  if (
    !typeOfTransfer &&
    !specialisation &&
    !startDate &&
    !treatmentLocation &&
    !searchRadius &&
    !potentialReceivers
  ) {
    return null;
  }

  const candidatesCalculationPending =
    auction?.candidates_status === CANDIDATES_STATUS_PENDING;
  const candidatesCalculationCompleted =
    auction?.candidates_status === CANDIDATES_STATUS_SUCCESS;

  return (
    <TransferDetailsWrapper>
      <TransferDetailsContent>
        <VerticalLayout padding={padding(2.5, 1, 3.5, 3)}>
          <Heading color={CUSTOM_BLACK} margin={margin(0, 0, 1, 0)}>
            {translations.patient.transferDetails.title}
          </Heading>
          <TransferDetailRow
            label={translations.patient.tooltipPatientMenu.typeOfTransfer}
            value={typeOfTransfer}
          />
          <TransferDetailRow
            label={translations.patient.tooltipPatientMenu.specialisation}
            value={specialisation}
          />
          <TransferDetailRow
            label={translations.patient.tooltipPatientMenu.startDate}
            value={startDate}
          />
          <TransferDetailRow
            label={translations.patient.tooltipPatientMenu.treatmentLocation}
            value={treatmentLocation}
          />
          <TransferDetailRow
            label={translations.patient.tooltipPatientMenu.searchRadius}
            value={searchRadius}
          />
          <TransferDetailRow
            label={translations.patient.tooltipPatientMenu.potentialReceivers}
            value={potentialReceivers}
            testId="search-candidates"
          />
          <HorizontalLayout
            margin={margin(1, 0, 1, 0.5)}
            padding={padding(0, 0, 0, 0.5)}
            style={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {candidatesCalculationPending && (
              <>
                <div style={{ position: "absolute", left: 0 }}>
                  <CircularProgress size={FONT_SIZE_16} />
                </div>
                <Body
                  fontSize={FONT_SIZE_16}
                  fontWeight={FONT_WEIGHT_REGULAR}
                  lineHeight={LINE_HEIGHT_20}
                  fontStyle="italic"
                  color={GREY_600}
                  margin={margin(0, 0, 0, 3)}
                >
                  {translations.search.candidatesCalculating}
                </Body>
              </>
            )}
          </HorizontalLayout>
          <RSButton
            color="secondary"
            disabled={!candidatesCalculationCompleted}
            id="go_to_search_merge"
            loading="na"
            onClick={() => {
              trackEvent({ name: TRACK_EVENTS.GO_TO_SEARCH_BUTTON_CLICKED });
              navigationHandlers.patient.goToSearch({
                patientId: auction.patient.id,
                auctionId: auction.id,
              });
            }}
            style={{
              width: dp(CTA_WIDTH),
              minWidth: dp(CTA_WIDTH),
              margin: margin(1, 0, 0, 0),
              boxSizing: "border-box",
            }}
            variant="contained"
          >
            {translations.search.setUpSearch}
          </RSButton>
        </VerticalLayout>
      </TransferDetailsContent>
    </TransferDetailsWrapper>
  );
}

export const PatientProfilePresenter = ({ auction }: { auction: Auction }) => {
  const getOntology = useGetOntology();

  if (!auction) return null;

  return (
    <PatientProfileWrapper>
      <ClinicPatientInfos auction={auction} getOntology={getOntology} />
      <DetailsWrapper>
        <StickyDetailsWrapper>
          <PatientProfileStatusBanner auction={auction} />
          <TransferDetails auction={auction} />
        </StickyDetailsWrapper>
      </DetailsWrapper>
    </PatientProfileWrapper>
  );
};

const PatientProfile = ({ auction }: { auction: Auction }) => {
  useCandidatesSynchronizer({ auction });
  const getOntology = useGetOntology();
  const print = usePrint();

  if (!auction) return null;

  if (print)
    return (
      <PrintPatientInfo auction={auction} forClinic getOntology={getOntology} />
    );

  return (
    <PatientNavigation patient={auction.patient} auction={auction}>
      <PatientProfilePresenter auction={auction} />
    </PatientNavigation>
  );
};

export default PatientProfile;
