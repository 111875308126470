import { isFutureDay } from "core/model/utils/dates";
import {
  BarthelAssessment,
  EarlyRehabAssessment,
  SingerAssessment,
} from "core/types";
import { isNumber } from "lodash";
import { toJS } from "react-forms-state";
import Translations from "translations/types";

function validateDate({
  date,
  errorMessage,
  score,
}: {
  date: number | undefined;
  errorMessage: string;
  score: number | undefined;
}): boolean | { customMessage: string } {
  if (isNumber(score)) {
    if (!date || isFutureDay(date)) {
      return { customMessage: errorMessage };
    }
  }
  return true;
}

export function validateSingerAssessmentDate(
  date: number | undefined,
  { translations }: { translations: Translations },
  globalValueMap: any,
): boolean | { customMessage: string } {
  const { singer_assessment_score: score } = toJS<{
    singer_assessment_score: SingerAssessment["score"];
  }>(globalValueMap);

  return validateDate({
    date,
    score,
    errorMessage:
      translations.patient.medicalDiagnosis.singerPatientProfile
        .assessmentDateValidation,
  });
}

export function validateBarthelAssessmentDate(
  date: number | undefined,
  { translations }: { translations: Translations },
  globalValueMap: any,
): boolean | { customMessage: string } {
  const { barthel_assessment_score: score } = toJS<{
    barthel_assessment_score: SingerAssessment["score"];
  }>(globalValueMap);

  return validateDate({
    date,
    score,
    errorMessage:
      translations.patient.medicalDiagnosis.assessmentDateValidation,
  });
}

export function validateEarlyRehabAssessmentDate(
  date: number | undefined,
  { translations }: { translations: Translations },
  globalValueMap: any,
): boolean | { customMessage: string } {
  const { early_rehab_assessment_score: score } = toJS<{
    early_rehab_assessment_score: EarlyRehabAssessment["score"];
  }>(globalValueMap);

  return validateDate({
    date,
    score,
    errorMessage:
      translations.patient.medicalDiagnosis.assessmentDateValidation,
  });
}

function validateScore({
  date,
  errorMessage,
  range,
  score,
}: {
  date: number | undefined;
  errorMessage: string;
  range: {
    lower: number;
    upper: number;
  };
  score: number | undefined;
}): boolean | { customMessage: string } {
  if (isNumber(date)) {
    if (!isNumber(score)) {
      return { customMessage: errorMessage };
    }

    if (score < range.lower || score > range.upper) {
      return { customMessage: errorMessage };
    }
  }
  return true;
}

export function validateSingerAssessmentScore(
  score: number | undefined,
  { translations }: { translations: Translations },
  globalValueMap: any,
): boolean | { customMessage: string } {
  const { singer_assessment_date: date } = toJS<{
    singer_assessment_date: SingerAssessment["date"];
  }>(globalValueMap);

  return validateScore({
    date,
    score,
    errorMessage:
      translations.patient.medicalDiagnosis.singerPatientProfile
        .singerSumValidation,
    range: {
      lower: 0,
      upper: 100,
    },
  });
}

export function validateBarthelAssessmentScore(
  score: number | undefined,
  { translations }: { translations: Translations },
  globalValueMap: any,
): boolean | { customMessage: string } {
  const { barthel_assessment_date: date } = toJS<{
    barthel_assessment_date: BarthelAssessment["date"];
  }>(globalValueMap);

  return validateScore({
    date,
    score,
    errorMessage: translations.patient.medicalDiagnosis.barthelValidation,
    range: {
      lower: 0,
      upper: 100,
    },
  });
}

export function validateEarlyRehabAssessmentScore(
  score: number | undefined,
  { translations }: { translations: Translations },
  globalValueMap: any,
): boolean | { customMessage: string } {
  const { early_rehab_assessment_date: date } = toJS<{
    early_rehab_assessment_date: EarlyRehabAssessment["date"];
  }>(globalValueMap);

  return validateScore({
    date,
    score,
    errorMessage:
      translations.patient.medicalDiagnosis.earlyRehabIndex
        .earlyRehabValidation,
    range: {
      lower: -325,
      upper: 0,
    },
  });
}
