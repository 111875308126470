/* eslint-disable no-case-declarations */
import { GetOntologyType } from "core/types";
import Translations from "translations/types";
import { OntologyType } from "../ontologies";

export function getKey(...args: any[]) {
  return args.toString();
}

export const concatStrings = (
  string: string,
  concat: string | null | undefined,
  link?: string,
) => `${string}${concat ? `${link || " "}${concat}` : ""}`;

export const grammaticalList = ({
  array,
  getOntology,
  ontologyType,
  translations,
  useShortValues,
}: {
  array?: Array<number | string>;
  getOntology?: GetOntologyType;
  ontologyType?: OntologyType;
  translations: Translations;
  useShortValues?: boolean;
}): string => {
  let arrayOfStrings: string[];

  if (!array?.length) return "";

  if (ontologyType && getOntology) {
    const getString = (s: number): string =>
      getOntology({ type: ontologyType, key: s, useShortValues });
    arrayOfStrings = array.map((n) => getString(n as number));
  } else {
    arrayOfStrings = array as string[];
  }

  if (arrayOfStrings.length === 1) return arrayOfStrings[0];

  return (
    arrayOfStrings.slice(0, -1).join(", ") +
    ` ${translations.general.and} ` +
    arrayOfStrings.slice(-1)
  );
};

export const capitaliseFirst = (string: string | null | undefined) =>
  `${string?.charAt(0).toUpperCase() || ""}${string?.slice(1) || ""}`;

export function isTitleCase(str: string): boolean {
  const words = str.split(" ");

  for (const word of words) {
    if (!/^[A-Z][a-z]*$/.test(word)) {
      return false;
    }
  }

  return true;
}

export const removeSpaces = (string?: string) => string?.replace(/\s+/g, "");

export const objectToQueryParamString = (object: {
  [index: string]: boolean | number | string | null | undefined;
}) => {
  let query = "";
  Object.entries(object)
    .filter(
      (entry: [string, boolean | number | string | null | undefined]) =>
        entry[1] != null,
    )
    .forEach(
      (
        entry: [string, boolean | number | string | null | undefined],
        index: number,
      ) => {
        const [key, value] = entry;
        let finalValue = value;
        switch (typeof value) {
          case "string": {
            finalValue = encodeURIComponent(value);
            break;
          }
          case "boolean": {
            finalValue = finalValue ? "true" : "false";
            break;
          }
          default:
            break;
        }

        query += `${index > 0 ? "&" : "?"}${key}=${finalValue}`;
      },
    );

  return query;
};

export const isEmail = (email: string) => {
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

  return emailRegex.test(email);
};

export function findEmailInMessage({
  message,
  searchString,
}: {
  message: string;
  searchString: string;
}) {
  const searchIndex = message.indexOf(searchString);

  if (searchIndex !== -1) {
    const remainingText = message.slice(searchIndex + searchString.length);
    const emailMatch = remainingText.match(/(\S+)/)?.[0];

    if (emailMatch && isEmail(emailMatch)) {
      return emailMatch;
    }
  }

  return null;
}

export const getAriaDescribedByValue = (
  ariaDescribedBy: (string | undefined | false)[],
) => ariaDescribedBy.filter(Boolean).join(" ").trim();

export function formatPhone(phoneNumber: string): string {
  if (phoneNumber.startsWith("+")) {
    phoneNumber = phoneNumber.substring(0, 3) + " " + phoneNumber.substring(3);

    const secondSpacePosition = 9;
    phoneNumber =
      phoneNumber.substring(0, secondSpacePosition) +
      " " +
      phoneNumber.substring(secondSpacePosition);
  } else {
    phoneNumber = phoneNumber.substring(0, 5) + " " + phoneNumber.substring(5);
  }

  return phoneNumber;
}

export const getCharacterCounter = ({
  characterLimit,
  translations,
  value,
}: {
  characterLimit: number | undefined;
  translations: Translations;
  value: unknown;
}): string | null => {
  if (!characterLimit) return null;

  const stringValue = value === null ? "" : value;
  if (typeof stringValue !== "string") return null;

  return translations.general.characterCounter({
    count: (characterLimit - stringValue.length).toString(),
    maxLength: characterLimit.toString(),
  });
};

export const validateCharacterLimit =
  (characterLimit: number) =>
  (
    value: string | null | undefined,
    { translations }: { translations: Translations },
  ): boolean | { customMessage: string } => {
    if (!value) return true;

    if (typeof value !== "string") return false;

    if (value.length > characterLimit) {
      return {
        customMessage: translations.assessments.formValidation.characterLimit({
          valueLength: value.length?.toString(),
          characterLimit: characterLimit?.toString(),
        }),
      };
    }

    return true;
  };
