import { Paper, Typography } from "@mui/material";
import { renderMultiLine } from "core/model/utils/text";
import {
  MESSAGING_BUBBLE_RECEIVED,
  MESSAGING_BUBBLE_SENT,
} from "ds_legacy/materials/colors";
import { dp, padding } from "ds_legacy/materials/metrics";
import { FONT_SIZE_14, LINE_HEIGHT_20 } from "ds_legacy/materials/typography";

export function EventMessagingBubbleV2({
  blur,
  isMyMessage,
  message,
}: {
  blur: boolean;
  isMyMessage: boolean;
  message?: string;
}) {
  if (!message) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: isMyMessage ? "flex-end" : "flex-start",
        width: "100%",
      }}
      data-testid="event_messaging_bubble"
    >
      <Paper
        elevation={0}
        sx={{
          backgroundColor: isMyMessage
            ? MESSAGING_BUBBLE_SENT
            : MESSAGING_BUBBLE_RECEIVED,
          borderRadius: `${dp(16)} ${dp(16)} ${dp(isMyMessage ? 0 : 16)} ${dp(
            isMyMessage ? 16 : 0,
          )}`,
          maxWidth: { xs: "75%", desktop: "55%" },
          padding: padding(1.5),
          minWidth: dp(100),
          wordWrap: "break-word",
        }}
      >
        <Typography
          sx={{
            fontSize: FONT_SIZE_14,
            lineHeight: LINE_HEIGHT_20,
            color: isMyMessage ? "common.white" : "common.black",
          }}
        >
          {blur ? (
            <>
              <div dangerouslySetInnerHTML={{ __html: "&nbsp; &nbsp;" }} />
            </>
          ) : typeof message === "string" ? (
            renderMultiLine(message)
          ) : (
            message
          )}
        </Typography>
      </Paper>
    </div>
  );
}
