import { EventType } from "../types";

export const EVENTS = {
  PATIENT_CREATED: 1,
  PATIENT_UPDATED: 2,
  PATIENT_DISCHARGED: 3,
  REQUEST_SENT: 4,
  REQUEST_ACCEPTED: 5,
  REQUEST_DECLINED: 6,
  RESPONSE_APPROVED: 7,
  RESPONSE_REJECTED: 8,
  VALIDATION_CANCELLED: 9,
  REFUSAL_CANCELLED: 10,
  AUCTION_STARTED: 11,
  AUCTION_CANCELLED: 12,
  CAREPROVIDER_ACCEPT_CANCELLED: 13,
  CAREPROVIDER_DECLINE_CANCELLED: 14,
  AUCTION_ALL_CONTACTED: 15,
  AUCTION_SUCCESS: 16,
  PATIENT_ARCHIVED: 17,
  CAREPROVIDER_TEST_SENT: 18,
  CAREPROVIDER_TEST_ANSWERED: 19,
  AUCTION_RESET: 20,
  CAREPROVIDER_UPDATED: 21,
  CAREPROVIDER_CREATED: 22,
  ONBOARDING_EMAIL_SENT: 23,
  CAREPROVIDER_STATUS_CHANGED: 24,
  CAREPROVIDER_ONBOARDING_STATUS_CHANGED: 25,
  REQUEST_MESSAGE_TO_CLINIC: 26,
  REQUEST_MESSAGE_TO_PROVIDER: 27,
  MAIL_BOUNCED: 28,
  REQUEST_CREATED: 29,
  AUCTION_STATUS_CHANGED: 30,
  SYSTEM_ERROR: 31,
  ENCRYPTED_MESSAGE: 32,
  CARESEEKER_STATUS_CHANGED: 33,
  SENT_TO_CALLCENTER: 34,
  CARESEEKER_UPDATED: 35,
  FILE_UPLOAD: 36,
  ACCOUNT_ACTIVATION_EMAIL: 37,
  ADMIN_RESET_PASSWORD: 38,
  ACCOUNT_CREATED: 39,
  ACCOUNT_UPDATED: 40,
  ACCOUNT_PERMISSIONS: 41,
  ACCOUNT_LOGIN: 42,
  USER_PASSWORD_RESET_EMAIL: 43,
  PASSWORD_RESET_VERIFY: 44,
  PASSWORD_RESET_SUCCESS: 45,
  ADMIN_SET_PASSWORD: 46,
  REQUEST_COST_COVERAGE_UPDATED: 47,
  CARESEEKER_CREATED: 48,
  PATIENT_REQUEST_UPDATED: 49,
  USER_PASSWORD_CHANGED: 50,
  MAIL_FAILURE: 51,
  EMAIL_INVITATION: 52,
  COMPANY_CREATED: 53,
  COMPANY_UPDATED: 54,
  COMPANY_STATUS_CHANGED: 55,
  REQUEST_AUTOMATICALLY_ARCHIVED: 56,
  COMPANY_RESOURCE_ADDED: 57,
  COMPANY_RESOURCE_REMOVED: 58,
  ACCOUNT_STATUS_CHANGED: 59,
  REQUEST_STATUS_CHANGE: 60,
  REQUEST_UNAVAILABLE: 61,
  REQUEST_AVAILABLE: 62,
  EMAIL_SENT: 63,
  PATIENT_AUTOMATICALLY_ARCHIVED: 64,
  SHARE_PATIENT_INFORMATION: 65,
  ELIGIBILITY_STATUS_CHANGED: 66,
  REQUEST_TO_BE_SENT: 67,
  REQUEST_DISMISSED: 68,
  SUGGESTION_CANCELLED: 69,
  AUCTION_TO_BE_STARTED: 70,
  REHAB_FORM_STATUS_CHANGED: 71,
  REHAB_FORM_CREATED: 72,
  REHAB_FORM_UPDATED: 73,
  DISMISSAL_CANCELLED: 74,
  ELIGIBILITY_MESSAGE: 75,
  ELIGIBILITY_FILE_UPLOAD: 76,
  WAITING_FOR_ELIGIBILITY: 77,
  SOCIAL_WORKER_ASSIGNEE_CHANGED: 78,
  INSURANCE_ASSIGNEE_CHANGED: 79,
  FAX_DELIVERED: 80,
  FAX_FAILED: 81,
  FAX_SENT: 82,
  FAX_NOT_AVAILABLE: 83,
  FAX_ATTEMPTED: 84,
  SESSION_KEY_CREATED: 85,
  SESSION_KEY_REVOKED: 86,
  PENDING_SESSION_KEY_CREATED: 87,
  AUCTION_PAUSED: 88,
  COST_PAYER_CHANGED: 89,
  SMS_SENT: 90,
  PATIENT_MISSING_INFO: 91,
  RECEIVER_MARKED_PREFERRED: 93,
  AUCTION_UPDATED: 94,
  REQUEST_MANUALLY_ARCHIVED: 96,
  REQUEST_MANUALLY_UNARCHIVED: 97,
  SEALD_ENCRYPTION_CREATED: 98,
  BROWSER_NOTIFICATION_SENT: 99,
  AUCTION_ELECTIVE_FLOW: 100,
  FILE_SHARED: 105,
  REQUEST_AUTOMATICALLY_UNARCHIVED: 106,
  FILE_UPLOADED: 107,
  FILE_SHARE_MODE_CHANGED: 108,
  FILE_ACCESS_REVOKED: 109,
  FILE_DELETED: 110,
  CAPACITY_UPDATED: 111,
  RECEIVER_UNMARKED_PREFERRED: 113,
  RECEIVER_EDITED_PREFERRED_REASON: 115,
  CONSULTANT_UPDATED: 116,
  CONSULTANT_CREATED: 117,
  CONSULTANT_FILE_CREATED: 118,
  CONSULTANT_FILE_DELETED: 119,
  CONSULTANT_DELETED: 120,
  REHAB_FORM_DELETED: 121,
  TRANSITIONAL_CARE_FORM_CREATED: 122,
  TRANSITIONAL_CARE_FORM_UPDATED: 123,
  TRANSITIONAL_CARE_FORM_DELETED: 124,
  SHARE_PATIENT_INFORMATION_AUTOMATICALLY: 125,
} as const;

export const FAX_EVENTS = [
  EVENTS.FAX_DELIVERED,
  EVENTS.FAX_FAILED,
  EVENTS.FAX_NOT_AVAILABLE,
  EVENTS.FAX_SENT,
];

export const COMPANY_EVENTS = [
  EVENTS.COMPANY_CREATED,
  EVENTS.COMPANY_RESOURCE_ADDED,
  EVENTS.COMPANY_RESOURCE_REMOVED,
  EVENTS.COMPANY_STATUS_CHANGED,
  EVENTS.COMPANY_UPDATED,
];

export const ACCOUNT_EVENTS = [
  EVENTS.ACCOUNT_ACTIVATION_EMAIL,
  EVENTS.ACCOUNT_CREATED,
  EVENTS.ACCOUNT_LOGIN,
  EVENTS.ACCOUNT_PERMISSIONS,
  EVENTS.ACCOUNT_UPDATED,
  EVENTS.ACCOUNT_STATUS_CHANGED,
  EVENTS.ADMIN_RESET_PASSWORD,
  EVENTS.ADMIN_SET_PASSWORD,
  EVENTS.EMAIL_INVITATION,
  EVENTS.PASSWORD_RESET_SUCCESS,
  EVENTS.USER_PASSWORD_CHANGED,
  EVENTS.USER_PASSWORD_RESET_EMAIL,
];

export const REQUEST_EVENTS = [
  EVENTS.CAREPROVIDER_ACCEPT_CANCELLED,
  EVENTS.CAREPROVIDER_DECLINE_CANCELLED,
  EVENTS.ENCRYPTED_MESSAGE,
  EVENTS.FILE_UPLOAD,
  EVENTS.PATIENT_REQUEST_UPDATED,
  EVENTS.REFUSAL_CANCELLED,
  EVENTS.REQUEST_ACCEPTED,
  EVENTS.REQUEST_AUTOMATICALLY_ARCHIVED,
  EVENTS.REQUEST_AVAILABLE,
  EVENTS.REQUEST_CREATED,
  EVENTS.REQUEST_DECLINED,
  EVENTS.REQUEST_DISMISSED,
  EVENTS.REQUEST_MESSAGE_TO_CLINIC,
  EVENTS.REQUEST_MESSAGE_TO_PROVIDER,
  EVENTS.REQUEST_SENT,
  EVENTS.REQUEST_STATUS_CHANGE,
  EVENTS.REQUEST_TO_BE_SENT,
  EVENTS.REQUEST_UNAVAILABLE,
  EVENTS.RESPONSE_APPROVED,
  EVENTS.RESPONSE_REJECTED,
  EVENTS.SHARE_PATIENT_INFORMATION,
  EVENTS.SUGGESTION_CANCELLED,
  EVENTS.VALIDATION_CANCELLED,
];

export const SENDER_EVENTS = [
  EVENTS.ACCOUNT_ACTIVATION_EMAIL,
  EVENTS.ACCOUNT_PERMISSIONS,
  EVENTS.AUCTION_ALL_CONTACTED,
  EVENTS.AUCTION_CANCELLED,
  EVENTS.AUCTION_PAUSED,
  EVENTS.AUCTION_STARTED,
  EVENTS.AUCTION_STATUS_CHANGED,
  EVENTS.AUCTION_SUCCESS,
  EVENTS.AUCTION_TO_BE_STARTED,
  EVENTS.CARESEEKER_CREATED,
  EVENTS.CARESEEKER_STATUS_CHANGED,
  EVENTS.CARESEEKER_UPDATED,
  EVENTS.ELIGIBILITY_FILE_UPLOAD,
  EVENTS.ELIGIBILITY_MESSAGE,
  EVENTS.ELIGIBILITY_STATUS_CHANGED,
  EVENTS.PATIENT_ARCHIVED,
  EVENTS.PATIENT_AUTOMATICALLY_ARCHIVED,
  EVENTS.PATIENT_CREATED,
  EVENTS.PATIENT_DISCHARGED,
  EVENTS.PATIENT_UPDATED,
  EVENTS.REHAB_FORM_CREATED,
  EVENTS.REHAB_FORM_STATUS_CHANGED,
  EVENTS.REHAB_FORM_UPDATED,
];

export const TECHNICAL_EVENTS = [
  EVENTS.EMAIL_SENT,
  EVENTS.MAIL_BOUNCED,
  EVENTS.MAIL_FAILURE,
  EVENTS.SYSTEM_ERROR,
];

export const RECEIVER_EVENTS = [
  EVENTS.ACCOUNT_ACTIVATION_EMAIL,
  EVENTS.ACCOUNT_PERMISSIONS,
  EVENTS.CAPACITY_UPDATED,
  EVENTS.CAREPROVIDER_CREATED,
  EVENTS.CAREPROVIDER_ONBOARDING_STATUS_CHANGED,
  EVENTS.CAREPROVIDER_STATUS_CHANGED,
  EVENTS.CAREPROVIDER_TEST_ANSWERED,
  EVENTS.CAREPROVIDER_TEST_SENT,
  EVENTS.CAREPROVIDER_UPDATED,
  EVENTS.ONBOARDING_EMAIL_SENT,
  EVENTS.SMS_SENT,
];

export const CONSULTANT_EVENTS = [
  EVENTS.CONSULTANT_UPDATED,
  EVENTS.CONSULTANT_CREATED,
  EVENTS.CONSULTANT_FILE_CREATED,
  EVENTS.CONSULTANT_FILE_DELETED,
];

export const EVENT_MAP: { [key in EventType]: string } = {
  [EVENTS.ACCOUNT_ACTIVATION_EMAIL]: "Account Activation Email Sent",
  [EVENTS.ACCOUNT_CREATED]: "Account Created",
  [EVENTS.ACCOUNT_LOGIN]: "Account Login",
  [EVENTS.ACCOUNT_PERMISSIONS]: "Account Permissions Modified",
  [EVENTS.ACCOUNT_STATUS_CHANGED]: "Account Status Changed",
  [EVENTS.ACCOUNT_UPDATED]: "Account Updated",
  [EVENTS.ADMIN_RESET_PASSWORD]: "Account Admin reset password",
  [EVENTS.ADMIN_SET_PASSWORD]: "Account Admin set password",
  [EVENTS.AUCTION_ALL_CONTACTED]: "Auction All contacted",
  [EVENTS.AUCTION_CANCELLED]: "Auction Cancelled",
  [EVENTS.AUCTION_ELECTIVE_FLOW]: "Auciton Elective flow",
  [EVENTS.AUCTION_PAUSED]: "Auction Paused",
  [EVENTS.AUCTION_RESET]: "Auction Reset",
  [EVENTS.AUCTION_STARTED]: "Auction Started",
  [EVENTS.AUCTION_STATUS_CHANGED]: "Auction Status changed",
  [EVENTS.AUCTION_SUCCESS]: "Auction Success",
  [EVENTS.AUCTION_TO_BE_STARTED]: "Auction To Be Started",
  [EVENTS.AUCTION_UPDATED]: "Auction Updated",
  [EVENTS.BROWSER_NOTIFICATION_SENT]: "Browser Notification Sent",
  [EVENTS.CAPACITY_UPDATED]: "Capacity Updated",
  [EVENTS.CAREPROVIDER_ACCEPT_CANCELLED]: "Accept cancelled by receiver",
  [EVENTS.CAREPROVIDER_CREATED]: "Receiver Created",
  [EVENTS.CAREPROVIDER_DECLINE_CANCELLED]: "Declined cancelled by receiver",
  [EVENTS.CAREPROVIDER_ONBOARDING_STATUS_CHANGED]: "Onboarding Status Changed",
  [EVENTS.CAREPROVIDER_STATUS_CHANGED]: "Receiver Status changed",
  [EVENTS.CAREPROVIDER_TEST_ANSWERED]: "Receiver Test answered",
  [EVENTS.CAREPROVIDER_TEST_SENT]: "Receiver Test Sent",
  [EVENTS.CAREPROVIDER_UPDATED]: "Receiver Updated",
  [EVENTS.CARESEEKER_CREATED]: "Careseeker Created",
  [EVENTS.CARESEEKER_STATUS_CHANGED]: "Clinic Status changed",
  [EVENTS.CARESEEKER_UPDATED]: "Careseeker Updated",
  [EVENTS.COMPANY_CREATED]: "Company Created",
  [EVENTS.COMPANY_RESOURCE_ADDED]: "Company Resource Added",
  [EVENTS.COMPANY_RESOURCE_REMOVED]: "Company Resource Removed",
  [EVENTS.COMPANY_STATUS_CHANGED]: "Company Status Changed",
  [EVENTS.COMPANY_UPDATED]: "Company Updated",
  [EVENTS.COST_PAYER_CHANGED]: "Cost Payer Changed",
  [EVENTS.DISMISSAL_CANCELLED]: "Dismissal Cancelled",
  [EVENTS.ELIGIBILITY_FILE_UPLOAD]: "Eligibility File Upload",
  [EVENTS.ELIGIBILITY_MESSAGE]: "Eligibility Message",
  [EVENTS.ELIGIBILITY_STATUS_CHANGED]: "Eligibility Status Changed",
  [EVENTS.EMAIL_INVITATION]: "Account Email Invitation",
  [EVENTS.EMAIL_SENT]: "Email Sent",
  [EVENTS.ENCRYPTED_MESSAGE]: "Request Encrypted Message",
  [EVENTS.FAX_ATTEMPTED]: "Fax Attempted",
  [EVENTS.FAX_DELIVERED]: "Fax Delivered to provider",
  [EVENTS.FAX_FAILED]: "Fax sent but failed to deliver to provider",
  [EVENTS.FAX_NOT_AVAILABLE]: "Fax unable to send",
  [EVENTS.FAX_SENT]: "Fax sent but not yet delivered to provider",
  [EVENTS.FILE_ACCESS_REVOKED]: "File Access Revoked",
  [EVENTS.FILE_DELETED]: "File Deleted",
  [EVENTS.FILE_SHARE_MODE_CHANGED]: "File Share Mode Changed",
  [EVENTS.FILE_SHARED]: "File Shared",
  [EVENTS.FILE_UPLOAD]: "File Upload",
  [EVENTS.FILE_UPLOADED]: "File Uploaded",
  [EVENTS.INSURANCE_ASSIGNEE_CHANGED]: "Insurance Assignee Changed",
  [EVENTS.MAIL_BOUNCED]: "Email Bounced",
  [EVENTS.MAIL_FAILURE]: "Email Failure",
  [EVENTS.ONBOARDING_EMAIL_SENT]: "Receiver Onboarding Email Sent",
  [EVENTS.PASSWORD_RESET_SUCCESS]: "Account Successful Password Reset",
  [EVENTS.PASSWORD_RESET_VERIFY]: "Password Reset Verify",
  [EVENTS.PATIENT_ARCHIVED]: "Patient Archived",
  [EVENTS.PATIENT_AUTOMATICALLY_ARCHIVED]: "Request automatically archived",
  [EVENTS.PATIENT_CREATED]: "Patient Created",
  [EVENTS.PATIENT_DISCHARGED]: "Patient Discharged",
  [EVENTS.PATIENT_MISSING_INFO]: "Patient Missing Information",
  [EVENTS.PATIENT_REQUEST_UPDATED]: "Request Patient updated",
  [EVENTS.PATIENT_UPDATED]: "Patient Updated",
  [EVENTS.PENDING_SESSION_KEY_CREATED]: "Pending Session Key Created",
  [EVENTS.RECEIVER_MARKED_PREFERRED]: "Marked as Patient Preferred",
  [EVENTS.RECEIVER_UNMARKED_PREFERRED]: "Unmarked as Patient Preferred",
  [EVENTS.RECEIVER_EDITED_PREFERRED_REASON]: "Edited Patient Preferred Reason",
  [EVENTS.REFUSAL_CANCELLED]: "Request Rejection cancelled by sender",
  [EVENTS.REHAB_FORM_CREATED]: "Rehab Form Created",
  [EVENTS.REHAB_FORM_STATUS_CHANGED]: "Rehab Form Status Changed",
  [EVENTS.REHAB_FORM_UPDATED]: "Rehab Form Updated",
  [EVENTS.REHAB_FORM_DELETED]: "Rehab Form Deleted",
  [EVENTS.REQUEST_ACCEPTED]: "Request Accepted",
  [EVENTS.REQUEST_AUTOMATICALLY_ARCHIVED]: "Request Archived",
  [EVENTS.REQUEST_AUTOMATICALLY_UNARCHIVED]: "Request Unarchived",
  [EVENTS.REQUEST_AVAILABLE]: "Request Available",
  [EVENTS.REQUEST_COST_COVERAGE_UPDATED]: "Request Cost Coverage Updated",
  [EVENTS.REQUEST_CREATED]: "Request Provider not active added to the search",
  [EVENTS.REQUEST_DECLINED]: "Request Declined",
  [EVENTS.REQUEST_DISMISSED]: "Request Dismissed",
  [EVENTS.REQUEST_MANUALLY_ARCHIVED]: "Request Manually Archived",
  [EVENTS.REQUEST_MANUALLY_UNARCHIVED]: "Request Manually Unarchived",
  [EVENTS.REQUEST_MESSAGE_TO_CLINIC]: "Request Message sent to sender",
  [EVENTS.REQUEST_MESSAGE_TO_PROVIDER]: "Request Message sent to receiver",
  [EVENTS.REQUEST_SENT]: "Request sent",
  [EVENTS.REQUEST_STATUS_CHANGE]: "Request Status Change",
  [EVENTS.REQUEST_TO_BE_SENT]: "Request To Be Sent",
  [EVENTS.REQUEST_UNAVAILABLE]: "Request Unavailable",
  [EVENTS.RESPONSE_APPROVED]: "Request Receiver approved by sender",
  [EVENTS.RESPONSE_REJECTED]: "Request Receiver rejected by sender",
  [EVENTS.SEALD_ENCRYPTION_CREATED]: "Seald Encryption Created",
  [EVENTS.SENT_TO_CALLCENTER]: "Sent to call center",
  [EVENTS.SESSION_KEY_CREATED]: "Session key Created",
  [EVENTS.SESSION_KEY_REVOKED]: "Session key revoked",
  [EVENTS.SHARE_PATIENT_INFORMATION]: "Request Patient information shared",
  [EVENTS.SMS_SENT]: "SMS Sent",
  [EVENTS.SOCIAL_WORKER_ASSIGNEE_CHANGED]: "SW assigned changed",
  [EVENTS.SUGGESTION_CANCELLED]: "Suggestion cancelled",
  [EVENTS.SYSTEM_ERROR]: "System error",
  [EVENTS.USER_PASSWORD_CHANGED]: "User Password Changed",
  [EVENTS.USER_PASSWORD_RESET_EMAIL]: "Account Password reset email sent",
  [EVENTS.VALIDATION_CANCELLED]: "Request Validation cancelled by sender",
  [EVENTS.WAITING_FOR_ELIGIBILITY]: "Waiting for Eligibilty",
  [EVENTS.CONSULTANT_CREATED]: "Consultant Created",
  [EVENTS.CONSULTANT_UPDATED]: "Consultant Updated",
  [EVENTS.CONSULTANT_DELETED]: "Consultant Deleted",
  [EVENTS.CONSULTANT_FILE_CREATED]: "Consultant File Created",
  [EVENTS.CONSULTANT_FILE_DELETED]: "Consultant File Deleted",
  [EVENTS.TRANSITIONAL_CARE_FORM_CREATED]: "Transitional Care Form Created",
  [EVENTS.TRANSITIONAL_CARE_FORM_UPDATED]: "Transitional Care Form Updated",
  [EVENTS.TRANSITIONAL_CARE_FORM_DELETED]: "Transitional Care Form Deleted",
  [EVENTS.SHARE_PATIENT_INFORMATION_AUTOMATICALLY]:
    "Request Patient information shared automatically",
};
