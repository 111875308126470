import {
  UNDO_REQUEST_ACTION_ACCEPT_REQUEST,
  UNDO_REQUEST_ACTION_MANUALLY_ARCHIVED,
  UNDO_REQUEST_ACTION_REQUEST_DECLINATION,
} from "core/consts";
import { AuctionRequest, QueryProgress } from "core/types";
import {
  EventSpecsEnhanced,
  getCancelAction,
} from "dsl/atoms/EventSpecs/utils";
import { useUndoRequestAction } from "dsl/hooks/useRequestAction";
import EventCard, { EventCardProps } from "dsl/molecules/Messenger/EventCard";
import { useCallback } from "react";
import { useTranslations } from "translations";

export type EventCardCareproviderProps = {
  auctionRequest: AuctionRequest;
  closeForm?: () => void;
  detached?: boolean;
  event: EventSpecsEnhanced;
  isDividerVisible: EventCardProps["isDividerVisible"];
  print?: boolean;
  shouldShowBefore: EventCardProps["shouldShowBefore"];
};

const useOnCancelActionCareprovider = ({
  auctionRequest,
  onComplete,
}: {
  auctionRequest: AuctionRequest;
  onComplete?: () => void;
}): [() => void, QueryProgress] => {
  const [undoAction, undoActionQueryProgress, undoActionType] =
    useUndoRequestAction({
      auctionRequest,
    });

  const cancelResponse = useCallback(async () => {
    switch (undoActionType) {
      case UNDO_REQUEST_ACTION_MANUALLY_ARCHIVED:
      case UNDO_REQUEST_ACTION_ACCEPT_REQUEST:
      case UNDO_REQUEST_ACTION_REQUEST_DECLINATION:
        {
          await undoAction({ onCompleted: onComplete });
        }
        break;
      default:
        console.error(`action not set up - ${undoActionType}`);
    }
  }, [undoAction, undoActionType]);
  return [cancelResponse, undoActionQueryProgress];
};

export function EventCardCareprovider({
  auctionRequest,
  closeForm,
  detached,
  event,
  isDividerVisible,
  print,
  shouldShowBefore,
}: EventCardCareproviderProps) {
  const isProviderSearchRequest = !!auctionRequest?.is_provider_search_request;
  const translations = useTranslations();
  const [onCancel, onCancelSubmitProgress] = useOnCancelActionCareprovider({
    auctionRequest,
    onComplete: closeForm,
  });

  return (
    <EventCard
      cancelAction={getCancelAction({
        requestActions: auctionRequest.request_actions,
        onCancel,
        eventType: event.type,
      })}
      cardFormat={{
        ...event.cardFormat,
        lastSeens: detached ? undefined : event.cardFormat.lastSeens,
      }}
      eventMessages={event.eventMessages}
      file={event.file}
      Icon={event.Icon}
      isDividerVisible={isDividerVisible}
      isEventUserInitiated={event.isEventUserInitiated}
      messages={event.messages}
      onSubmitQueryProgress={onCancelSubmitProgress}
      print={print}
      searchType={event.searchType}
      sentByLabel={
        isProviderSearchRequest
          ? translations.patient.patient
          : event.sentByLabel
      }
      shouldShowBefore={shouldShowBefore}
      statusHeader={event.statusHeader}
      timestamp={event.timestamp}
      type={event.type}
      patientId={auctionRequest.auction?.patient?.user_id}
    />
  );
}

export default EventCardCareprovider;
