import { isProd } from "core/model/utils/featureFlags";
import { usePersistReducer } from "dsl/hooks";

const activateOnePageAssessment = "activateOnePageAssessment";

export const useOnePageAssessment = () => {
  const getInitialState = () => {
    const storedValue = localStorage.getItem(activateOnePageAssessment);
    return storedValue && !isProd ? JSON.parse(storedValue) : false;
  };

  const [value, setValue] = usePersistReducer(
    activateOnePageAssessment,
    (state) => {
      return {
        ...state,
        isActive: !state.isActive,
      };
    },
    {
      isActive: getInitialState(),
    },
  );

  const toggleOnePageAssessment = () => {
    setValue((prev: { isActive: boolean }) => !prev.isActive);
  };

  return {
    isOnePageAssessment: value.isActive,
    toggleOnePageAssessment,
  };
};
