import { GET_REHAB_FORMS } from "core/apollo/graphql";
import {
  PREDICAMENT_STATE_NO,
  PREDICAMENT_STATE_YES,
  REHAB_FORM_NAMES,
  REHAB_FORM_TYPES,
  SEARCH_ACTION_DELETE_REHAB_FORMS,
  SEARCH_ACTION_GENERATE_REHAB_FORMS,
  YES_NO_FIELD_OPTIONS,
} from "core/consts";
import { getSearchActions } from "core/model/auctions";
import { convertStringToDate } from "core/model/utils/dates";
import { getErrorMessage } from "core/model/utils/errors";
import { grammaticalList } from "core/model/utils/strings";
import {
  AnyObject,
  Auction,
  GetOntologyType,
  InfectionAndGerms,
  PredicamentState,
  RehabForm,
  RehabFormName,
  RehabFormType,
  RehabForms,
  ValueOf,
  YesNoFieldOption,
} from "core/types";
import { getTime, isValid, parse } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useConfirmationDialog } from "ds_legacy/components/ConfirmationDialog/useConfirmationDialog";
import { scrollToTop } from "dsl/hooks/useScrollTo";
import useSearchAction from "dsl/hooks/useSearchAction";
import { convertInfectionsToOntologiesIds } from "dsl/molecules/Messenger/Forms/Decline/NoCapacityReasons/values";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { PDFForm } from "pdf-lib";
import { CSSProperties, useCallback } from "react";
import {
  convertEncryptedFieldIn,
  convertEncryptedFieldOut,
} from "react-forms-state";
import { FormContainerState } from "react-forms-state/src/FormContainer";
import { useTranslations } from "translations";
import Translations from "translations/types";
import { formatDate } from "../utils";
import {
  GeneralFormPensionData,
  GeneralFormPensionDataOld,
} from "./GeneralForm/Pension/modelDefinition";
import {
  GeneralFormUniversalData,
  GeneralFormUniversalDataOld,
} from "./GeneralForm/Universal/modelDefinition";
import {
  MedicalFormPensionData,
  MedicalFormPensionDataOld,
} from "./MedicalForm/Pension/modelDefinition";
import {
  MedicalFormUniversalData,
  MedicalFormUniversalDataOld,
} from "./MedicalForm/Universal/modelDefinition";
import { getRehabFormConfig } from "./configs";

export const PENSION_INSURANCE_NUMBER_CHARACTER_LIMIT = 12;
export const INFECTIONS_CHARACTER_LIMIT = 28;
export const ICD_CODE_CHARACTER_LIMIT = 5;
export const DRG_CODE_CHARACTER_LIMIT = 4;
export const IK_NUMBER_CHARACTER_LIMIT = 9;
export const SEND_DOCUMENTS_CHARACTER_LIMIT = 34;

export const areNewRehabFormsActive = (auction: Auction): boolean => {
  const { [SEARCH_ACTION_GENERATE_REHAB_FORMS]: action } =
    getSearchActions(auction);

  return !!(action && !action.context?.disabled);
};

type RehabFormTypeMapping = {
  [REHAB_FORM_NAMES.MEDICAL_PENSION]: {
    new: MedicalFormPensionData;
    old: MedicalFormPensionDataOld;
  };
  [REHAB_FORM_NAMES.GENERAL_PENSION]: {
    new: GeneralFormPensionData;
    old: GeneralFormPensionDataOld;
  };
  [REHAB_FORM_NAMES.MEDICAL_UNIVERSAL]: {
    new: MedicalFormUniversalData;
    old: MedicalFormUniversalDataOld;
  };
  [REHAB_FORM_NAMES.GENERAL_UNIVERSAL]: {
    new: GeneralFormUniversalData;
    old: GeneralFormUniversalDataOld;
  };
};

export type RehabFormDataMapped<T extends RehabFormName> =
  RehabFormTypeMapping[T]["new"];

export type OldRehabFormDataMapped<T extends RehabFormName> =
  RehabFormTypeMapping[T]["old"];

export const getRehabFormKey = (formType: RehabFormType): keyof RehabForms =>
  formType === REHAB_FORM_TYPES.GENERAL ? "general_form" : "medical_form";

export const useGetRehabFormSubmit = ({
  auction,
  formName,
  forms,
}: {
  auction: Auction;
  formName: RehabFormName;
  forms: RehabForms | undefined;
}) => {
  const translations = useTranslations();
  const [generateRehabForm, generateRehabFormProgress, resetProgress] =
    useSearchAction({
      actionType: SEARCH_ACTION_GENERATE_REHAB_FORMS,
    });
  const notify = useNotification();

  const onSubmit = useCallback(
    async (
      formData: RehabFormDataMapped<typeof formName>,
      formState: FormContainerState,
    ) => {
      if (!formState.dirty || !formName) return;

      const { formSubType, formType } = getRehabFormConfig(formName);

      const formKey = getRehabFormKey(formType);

      const rehabForms: RehabForms = {
        [formKey]: {
          form_data: convertEncryptedFieldOut(JSON.stringify(formData)),
          rehab_form_subtype: formSubType,
        },
      };

      const updatedAuction = {
        ...auction,
        rehab_forms: rehabForms,
      } as Auction;

      await generateRehabForm({
        auction,
        context: {
          auction: updatedAuction,
          rehab_form_type: formType,
        },
        onCompleted: (generated, client) => {
          if (!generated?.rehab_forms?.[formKey]) {
            throw new Error("no rehab form returned on submit");
          }

          client.writeQuery({
            query: GET_REHAB_FORMS,
            variables: {
              auctionId: auction.id,
              patientId: auction.patient.id,
            },
            data: {
              auction: {
                id: auction.id,
                rehab_forms: {
                  ...(forms ?? {}),
                  [formKey]: generated.rehab_forms[formKey],
                },
                seald_encryption_context: generated.seald_encryption_context,
                session_key_context: generated.session_key_context,
              },
            },
          });
          notify({
            message: translations.patientForms.transitionalCareForm.pdfCreated,
            actions: [
              {
                label: translations.patientForms.transitionalCareForm.toTheTop,
                onClick: () => scrollToTop(),
              },
            ],
            type: "success",
          });
          resetProgress();
        },
        onError: (err) => {
          console.error(`error saving rehab forms - ${getErrorMessage(err)}`);
          notify({
            message: translations.auctionRequest.tryAgain,
            type: "error",
          });
        },
      });
    },
    [convertEncryptedFieldOut, generateRehabForm, auction, forms],
  );

  return { onSubmit, onSubmitProgress: generateRehabFormProgress };
};

export const useDeleteConfirmation = ({
  auction,
  formName,
  formOnChange,
}: {
  auction: Auction;
  formName: RehabFormName;
  formOnChange?: (value: any, statePath: string, validation: any) => void;
}) => {
  const translations = useTranslations();
  const [deleteForm, deleteFormProgress, resetProgress] = useSearchAction({
    actionType: SEARCH_ACTION_DELETE_REHAB_FORMS,
  });
  const [confirmProps, confirm] = useConfirmationDialog();

  const onDelete = async (formValue: RehabFormDataMapped<typeof formName>) => {
    try {
      const confirmed = await confirm({
        title: translations.patientForms.fileDeletion.title,
        content: translations.patientForms.fileDeletion.text,
        submitText: translations.patientForms.fileDeletion.confirmButton,
        cancelText: translations.patientForms.fileDeletion.cancelButton,
        confirmButtonProps: {
          color: "error",
          variant: "contained",
        },
      });

      if (!confirmed) return null;

      const { formType } = getRehabFormConfig(formName);

      await deleteForm({
        auction,
        context: {
          rehab_form_type: formType,
        },
        onCompleted: (_, client) => {
          client.writeQuery({
            query: GET_REHAB_FORMS,
            variables: {
              auctionId: auction.id,
              patientId: auction.patient.id,
            },
            data: {
              auction: {
                id: auction.id,
                rehab_forms: null,
              },
            },
          });
        },
      });

      if (typeof formOnChange === "function")
        Object.keys(formValue).forEach((key) => formOnChange(null, key, null));
      resetProgress();
    } catch (err) {
      console.error(
        `error deleting transitional care form: ${getErrorMessage(err)}`,
      );
    }
  };

  return { confirmProps, onDelete, deleteFormProgress };
};

export const getRehabFormInputValue = ({
  formName,
  forms,
}: {
  formName: RehabFormName;
  forms: RehabForms | undefined;
}): {
  form: RehabForm | null;
  formData: RehabFormDataMapped<typeof formName> | null;
} => {
  const { convertOldFormDataToNew, formType } = getRehabFormConfig(formName);

  if (!forms) return { form: null, formData: null };

  const formKey = getRehabFormKey(formType);

  const form = forms?.[formKey];

  if (!form || !form.form_data) return { form: null, formData: null };

  const stringified = convertEncryptedFieldIn(form.form_data as AnyObject);

  let parsedFormData = JSON.parse(stringified) as RehabFormDataMapped<
    typeof formName
  >;

  if (form.was_migrated) {
    parsedFormData = convertOldFormDataToNew({
      formDataOld: parsedFormData as OldRehabFormDataMapped<typeof formName>,
    });
  }

  return {
    form,
    formData: parsedFormData,
  };
};

export const yesNoUnknownToPredicament = (
  yesNoField: YesNoFieldOption | null | undefined,
): PredicamentState | null => {
  switch (yesNoField) {
    case YES_NO_FIELD_OPTIONS.YES:
      return PREDICAMENT_STATE_YES;
    case YES_NO_FIELD_OPTIONS.NO:
      return PREDICAMENT_STATE_NO;
    default:
      return null;
  }
};

export const booleanToPredicamentState = (
  boolValue: boolean | null | undefined,
): PredicamentState | null => {
  switch (boolValue) {
    case true:
      return PREDICAMENT_STATE_YES;
    case false:
      return PREDICAMENT_STATE_NO;
    default:
      return null;
  }
};

export const convertActivableInputToPredicamentState = (
  value: string | null,
): PredicamentState | null => {
  if (value === null) return PREDICAMENT_STATE_NO;
  return PREDICAMENT_STATE_YES;
};

export const auctionBirthdatetoUnix = (auction: Auction) => {
  const decryptedDate = auction.patient?.profile?.birth_date?.decrypted;

  if (!decryptedDate) return null;

  const birthDate = convertStringToDate(decryptedDate, "de");

  if (!isValid(birthDate)) return null;

  return getTime(birthDate) / 1000;
};

export const getActivableStyles = (show: boolean): CSSProperties => {
  if (show) return {};
  return {
    display: "none",
  };
};

export function getInfection(
  auction: Auction,
  getOntology: GetOntologyType,
  translations: Translations,
) {
  const hasInfection =
    auction.patient.diagnosis?.infection_and_germs_state ===
    PREDICAMENT_STATE_YES;

  const infections = auction.patient.diagnosis?.infection_and_germs;

  if (!hasInfection || !infections) return null;

  const infectionKeys = Object.keys(infections) as Array<
    keyof InfectionAndGerms
  >;
  const trueInfections = infectionKeys.filter(
    (key) => infections[key] === true,
  );

  const infectionsIds = convertInfectionsToOntologiesIds(trueInfections);

  let infectionString = grammaticalList({
    array: infectionsIds,
    getOntology,
    ontologyType: "infectionAndGerms",
    translations,
  });

  if (infections.other) {
    infectionString = `${translations.general.other}${
      translations.general.colon
    } ${infections.other}${infectionString ? `, ${infectionString}` : ""}`;
  }

  return infectionString || null;
}

export const stringDateToUnix = ({
  date,
}: {
  date: string | null | undefined;
}) => {
  if (!date) {
    return null;
  } else {
    const parsedDate = parse(date, "dd.MM.yyyy", new Date());
    if (isValid(parsedDate)) {
      const utcDate = zonedTimeToUtc(parsedDate, "UTC");
      const unixTimestamp = Math.floor(utcDate.getTime() / 1000);
      return unixTimestamp;
    } else {
      return null;
    }
  }
};

export const getICDCode = (
  auction: Auction,
  icdCodeIndex: 1 | 2 | 3 | 4 | 5,
) => {
  if (icdCodeIndex === 1 && auction?.patient?.diagnosis?.icd_code?.code) {
    return auction.patient.diagnosis.icd_code.code;
  }

  const codes = auction?.patient?.diagnosis?.additional_icd_codes ?? [];

  return (
    codes.at(icdCodeIndex - 1)?.code?.slice(0, ICD_CODE_CHARACTER_LIMIT) || null
  );
};

export const getPatientStreetHouseNumber = (auction: Auction) =>
  [
    auction.profile?.search_location?.street,
    auction.profile?.search_location?.encrypted_house_number?.decrypted,
  ]
    .truthy()
    .join(" ");

export const getHospitalName = (auction: Auction) =>
  auction.patient?.careseeker?.name;
export const getHospitalAddress = (auction: Auction) =>
  [
    auction.patient?.careseeker?.address?.street,
    auction.patient?.careseeker?.address?.street_number,
    auction.patient?.careseeker?.address?.zipcode,
    auction.patient?.careseeker?.address?.city,
  ]
    .truthy()
    .join(" ");

export const getPatientCity = (auction: Auction) => {
  const city = auction.profile?.search_location?.city;
  if (city?.includes(",")) {
    return city.split(",")[0].trim();
  }
  return city;
};

export const handlePdfDateField = <T extends RehabFormDataMapped<any>>({
  form,
  formFieldName,
  locale,
  removeSeparators,
  value,
}: {
  form: PDFForm;
  formFieldName: keyof T;
  locale: Locale;
  removeSeparators?: boolean;
  value?: ValueOf<T>;
}) => {
  if (!value) return;

  let date = formatDate(value as number, locale);
  if (removeSeparators) {
    date = date.replaceAll(".", "").replaceAll("/", "");
  }
  form.getTextField(formFieldName as string).setText(date);
};
