import { TRACK_EVENTS } from "core/consts";
import { getQueryVariable } from "core/model/utils/urls";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTracking } from "react-tracking";
import { useOnLogout } from "reduxentities/actions";
import { useLoggedInAccount } from "reduxentities/selectors";

export default function LogoutPage({
  navigateTo = "/auth",
}: {
  navigateTo?: string;
}) {
  const { trackEvent } = useTracking();

  const onLogout = useOnLogout();
  const isLogged = useLoggedInAccount() != null;
  const navigate = useNavigate();

  useEffect(() => {
    const reason = getQueryVariable(location.search, "reason");
    if (reason) {
      sessionStorage.setItem("logout_reason", reason);
    }

    if (isLogged) {
      let tokenExpired = false;
      switch (reason) {
        case "session_expired": {
          trackEvent({ name: TRACK_EVENTS.TRANSPORT_SESSION_EXPIRED });
          tokenExpired = true;
          break;
        }
        case "unauthorized": {
          trackEvent({ name: TRACK_EVENTS.TRANSPORT_UNAUTHORIZED });
          tokenExpired = true;
          break;
        }
        case "password_reset": {
          trackEvent({ name: TRACK_EVENTS.TRANSPORT_PASSWORD_RESET });
          break;
        }
        case "password_change": {
          trackEvent({ name: TRACK_EVENTS.TRANSPORT_PASSWORD_CHANGE });
          tokenExpired = true;
          break;
        }
        default: {
          trackEvent({ name: TRACK_EVENTS.TRANSPORT_LOGGED_OUT });
          break;
        }
      }
      onLogout({ tokenExpired });
    } else {
      navigate(navigateTo);
    }
  }, [isLogged]);

  return null;
}
