import { Section } from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";
import { RequestListElement } from "./RequestsList";

export const REQUEST_LIST_ELEMENT_NAME = "request_list";

export const SectionE = ({ auctionId }: { auctionId: number }) => {
  const translations = useTranslations();

  return (
    <Section
      title={translations.patientForms.transitionalCareForm.sectionE.title}
    >
      <RequestListElement
        auctionId={auctionId}
        elementName={REQUEST_LIST_ELEMENT_NAME}
      />
    </Section>
  );
};
