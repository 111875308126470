import {
  APP_ACP,
  APP_B2C,
  APP_CAREPROVIDER,
  APP_CLINIC,
  APP_PROVIDER_SEARCH,
  COUNTRY_DE,
  COUNTRY_FR,
} from "core/consts";
import {
  AlgoliaApiKeysByCountry,
  AppType,
  ConfigType,
  Country,
  Env,
} from "core/types";
import { fromJS } from "immutable";

let app: AppType | null = null;

export const getApp = () => app;

export function setApp(appName: AppType) {
  app = appName;
}

export const ENV_STAGING = "staging";
export const ENV_PREPROD = "preprod";
export const ENV_PRODUCTION = "production";
export const ENV_DEVELOPMENT = "development";
export const ENV_DEMO = "demo";
export const hasWindow = typeof window !== "undefined";
export const isFrance = hasWindow && window.location?.hostname?.endsWith(".fr");

const ALGOLIA_KEYS: AlgoliaApiKeysByCountry = {
  [COUNTRY_DE]: {
    [APP_ACP]: "916100f98c0ea60a1641e2235ffe962a",
    [APP_B2C]:
      "Nzc3ZWU3ZjFjZmNiOGMwOTE1MzllNjAwNzE0NWJlNjY2OWM5NmVjZDJkODcyMjQ0MTcxZTJmOTBmMDU5NGJmZHJlc3RyaWN0SW5kaWNlcz0lNUIlMjJ6aXBjb2RlczIwMjMlMjIlNUQ=",
    [APP_CAREPROVIDER]:
      "OTA3MDViZWJmMDk2N2EzZGMwZjRkNzQ5YjllMTVhYmFmMTIyNmYzZDI4YmYxMzliMzBiN2NlMTBkNGY1N2UxY3Jlc3RyaWN0SW5kaWNlcz0lNUIlMjJwYXRpZW50c19TVEFHSU5HJTIyJTJDJTIycGF0aWVudHNfUFJFUFJPRCUyMiUyQyUyMnBhdGllbnRzX1BST0RVQ1RJT04lMjIlMkMlMjJjYXJlcHJvdmlkZXJzX1NUQUdJTkclMjIlMkMlMjJjYXJlcHJvdmlkZXJzX1BSRVBST0QlMjIlMkMlMjJjYXJlcHJvdmlkZXJzX1BST0RVQ1RJT04lMjIlMkMlMjJ6aXBjb2RlczIwMjMlMjIlNUQ=",
    [APP_CLINIC]:
      "Mjc2NGUzOTY1Njg3MDJlNmI1YTZhZTI5NzYyZTE3ODI0ZGMyNTM3NGI4NzBkMTZhMWUwNjhjY2UxM2NlYjg3NnJlc3RyaWN0SW5kaWNlcz0lNUIlMjJjYXJlcHJvdmlkZXJzX1NUQUdJTkclMjIlMkMlMjJjYXJlcHJvdmlkZXJzX1BSRVBST0QlMjIlMkMlMjJjYXJlcHJvdmlkZXJzX1BST0RVQ1RJT04lMjIlMkMlMjJpbnN1cmFuY2VzX1NUQUdJTkclMjIlMkMlMjJpbnN1cmFuY2VzX1BSRVBST0QlMjIlMkMlMjJpbnN1cmFuY2VzX1BST0RVQ1RJT04lMjIlMkMlMjJwYXRpZW50c19TVEFHSU5HJTIyJTJDJTIycGF0aWVudHNfUFJFUFJPRCUyMiUyQyUyMnBhdGllbnRzX1BST0RVQ1RJT04lMjIlMkMlMjJ6aXBjb2RlczIwMjMlMjIlMkMlMjJpY2RfY29kZXMlMjIlMkMlMjJtZWRpY2FsX3N1cHBsaWVzJTIyJTVE",
    [APP_PROVIDER_SEARCH]:
      "YmUwZWE0MzQ4NDc1ZDcwNDM0MDYyODkyM2U3NGNjMjRjYmRjNGRhYmE1ZGM4NTU5OWVhYmY4ZTVmOTNjNTE0YnJlc3RyaWN0SW5kaWNlcz0lNUIlMjJwcm92aWRlcnNlYXJjaF9TVEFHSU5HJTIyJTJDJTIycHJvdmlkZXJzZWFyY2hfUFJFUFJPRCUyMiUyQyUyMnByb3ZpZGVyc2VhcmNoX1BST0RVQ1RJT04lMjIlMkMlMjJ6aXBjb2RlczIwMjMlMjIlNUQ=",
  },
  [COUNTRY_FR]: {
    [APP_ACP]: "e980465e0ba771feff9fa46ef90c152f",
    [APP_B2C]: "n/a",
    [APP_CAREPROVIDER]:
      "YTliNTBhNjQ5ZDNlZWEwZjA2ZTRjYmVmYjgzMTRiYzVhM2FkZGUwYjA0NDY3MTg4NWNlMzczMmQ4M2NhZGYyMHJlc3RyaWN0SW5kaWNlcz0lNUIlMjJwYXRpZW50c19QUk9EVUNUSU9OJTIyJTJDJTIyY2FyZXByb3ZpZGVyc19QUk9EVUNUSU9OJTIyJTJDJTIyemlwY29kZXMyMDIzJTIyJTVE",
    [APP_CLINIC]:
      "ZDM1MTI4MmJjNDM1OGIxNWQyOGVhMzM3NWY2OTYyNDIzY2Y0ZjI1YzgyYzZkYmFhMWRmMGRhYTA1M2E0NDdhOHJlc3RyaWN0SW5kaWNlcz0lNUIlMjJjYXJlcHJvdmlkZXJzX1BST0RVQ1RJT04lMjIlMkMlMjJwYXRpZW50c19QUk9EVUNUSU9OJTIyJTJDJTIyemlwY29kZXMyMDIzJTIyJTVE",
    [APP_PROVIDER_SEARCH]: "n/a",
  },
};

const ALGOLIA_APP = {
  [COUNTRY_DE]: "19R893IBVD",
  [COUNTRY_FR]: "25ZFFO4C0C",
} as const;

const getAlgoliaKeys = (
  app: AppType | null,
  country: Country,
): ConfigType["algolia"] => ({
  apiKey: app ? ALGOLIA_KEYS[country][app] : "",
  appId: ALGOLIA_APP[country],
});

const getBase = (
  app: AppType | null,
  country: Country,
): Partial<ConfigType> => ({
  backend: {
    protocol: "https",
    port: 443,
  },
  algolia: getAlgoliaKeys(app, country),
});

const faqUrl = {
  DE: "https://help.recaresolutions.com/",
  FR: "https://help.recaresolutions.fr/",
};

// export for test
export const tierConf: Record<Env, Partial<ConfigType>> = {
  production: {
    mapbox:
      "pk.eyJ1IjoiY2hhcmxlc2NvdGUiLCJhIjoiY2pwaThxdTN4MGhvazN2cXcxcHg1d2h0ZSJ9.Mux3R3v_1oBJ5Uc2Den4NQ",
    backend: {
      host: isFrance ? "api.recaresolutions.fr" : "api.recaresolutions.com",
    },
    intercom: {
      key: "s7bhk3oq",
      faqUrl,
    },
    seald: isFrance
      ? {
          appId: "067366c2-49e8-4a36-97d2-728e0c6555cb",
          apiUrl: "https://api.seald.io/",
          ssksUrl: "https://ssks.seald.io/",
        }
      : {
          appId: "14caf043-6062-4465-9bc3-55fed8aee42c",
          apiUrl: "https://api.seald.io/",
          ssksUrl: "https://ssks.seald.io/",
        },
  },
  demo: {
    mapbox:
      "pk.eyJ1IjoiY2hhcmxlc2NvdGUiLCJhIjoiY2pwaThxdTN4MGhvazN2cXcxcHg1d2h0ZSJ9.Mux3R3v_1oBJ5Uc2Den4NQ",
    backend: {
      host: "api-preprod.recaresolutions.com",
    },
    intercom: {
      key: "arwx3wb7",
      faqUrl,
    },
    seald: {
      appId: "9bbc6d92-5464-49ea-886b-b8b52ad08ff2",
      apiUrl: "https://api.seald.io/",
      ssksUrl: "https://ssks.seald.io/",
    },
  },
  preprod: {
    mapbox:
      "pk.eyJ1IjoiY2hhcmxlc2NvdGUiLCJhIjoiY2pwaThxdTN4MGhvazN2cXcxcHg1d2h0ZSJ9.Mux3R3v_1oBJ5Uc2Den4NQ",
    backend: {
      host: "api-preprod.recaresolutions.com",
    },
    intercom: {
      faqUrl,
      key: "arwx3wb7",
    },
    seald: {
      appId: "9bbc6d92-5464-49ea-886b-b8b52ad08ff2",
      apiUrl: "https://api.seald.io/",
      ssksUrl: "https://ssks.seald.io/",
    },
  },
  staging: {
    mapbox:
      "pk.eyJ1IjoibGl2ZXRlc3QiLCJhIjoiY2p6Nnl1dzVtMDZ2ZTNtbnlldmhsc2w0eCJ9.ziQLuzK7AJIQyhAJqWm_yA",
    backend: {
      host: process.env.PR_NAME
        ? `${process.env.PR_NAME}-backendpr.staging.recaresolutions.com`
        : "api-staging.recaresolutions.com",
      url: process.env.PR_NAME
        ? `https://${process.env.PR_NAME}-backendpr.staging.recaresolutions.com`
        : "https://api-staging.recaresolutions.com",
    },
    intercom: {
      key: "arwx3wb7",
      faqUrl,
    },
    seald: {
      appId: "b0739385-c414-41a0-a23b-72a89554e984",
      apiUrl: "https://api.staging-0.seald.io/",
      ssksUrl: "https://ssks.staging-0.seald.io/",
    },
  },
  development: {
    mapbox:
      "pk.eyJ1IjoibGl2ZXRlc3QiLCJhIjoiY2p6Nnl1dzVtMDZ2ZTNtbnlldmhsc2w0eCJ9.ziQLuzK7AJIQyhAJqWm_yA",
    backend: {
      host: "api-staging.recaresolutions.com",
    },
    intercom: {
      key: "arwx3wb7",
      faqUrl,
    },
    seald: {
      appId: "b0739385-c414-41a0-a23b-72a89554e984",
      apiUrl: "https://api.staging-0.seald.io/",
      ssksUrl: "https://ssks.staging-0.seald.io/",
    },
  },
};

const envs: Array<Env> = [
  "development",
  "staging",
  "demo",
  "preprod",
  "production",
];

function overrideBackendHost(tierConfig: Partial<ConfigType>) {
  if (window.localStorage?.getItem) {
    let backendHostOverride;
    try {
      backendHostOverride = window.localStorage.getItem(
        "backendConfigOverride",
      );
    } catch (err) {
      console.error(err, `overrideBackendHost: ${backendHostOverride}`);
    }
    if (backendHostOverride) {
      tierConfig.backend = { host: backendHostOverride };
    }
  }
  return tierConfig;
}

export function getConfig(environment?: Env) {
  if (!environment) {
    console.error("Missing env, defaulting to development");
    environment = "development";
  }

  const isProduction = env === ENV_PRODUCTION;
  let tierConfig = tierConf[environment];

  if (!isProduction) {
    tierConfig = overrideBackendHost(tierConfig);
  }

  const topLevelDomain = isFrance ? "fr" : "com";
  const country = isFrance ? COUNTRY_FR : COUNTRY_DE;
  const app = getApp();

  return fromJS(getBase(app, country))
    .mergeDeep(fromJS(tierConfig))
    .mergeDeep({
      environment,
      version: process.env.BUILD_VERSION,
      domain: `recaresolutions.${topLevelDomain}`,
    })
    .toJS();
}

function getEnvironmentFromLocalStorage() {
  let environment: Env = "staging";
  if (window.localStorage?.getItem) {
    try {
      environment =
        (window.localStorage.getItem("env") as Env) ||
        process.env.ENV ||
        "staging";
    } catch (err) {
      console.error(
        err,
        `getBackendConfig environment: ${JSON.stringify(environment)}`,
      );
    }
  }
  return environment;
}

export function getBackendConfig() {
  const environment = getEnvironmentFromLocalStorage();
  return getConfig(environment).backend;
}

const env = (process.env.ENV as Env) || (global as unknown as { ENV: Env }).ENV;

if (env == null) {
  console.warn(`process.env.ENV/global.ENV is not defined`);
} else if (envs.find((e) => env == e) === undefined) {
  console.warn(
    `process.env.ENV/global.ENV isn't a valid env : ${
      process.env.ENV || ""
    }, valids are ${envs.join(" / ")}`,
  );
}

export function getStaticAsset(assetPath: string): string {
  return `https://assets.recaresolutions.com/recare/${assetPath}`;
}

const Config: ConfigType = getConfig(env);

export default Config;
export const BackendConfig = Config.backend;
